import { createWebHashHistory, createRouter } from "vue-router";
import store from "./store";

// NB: We are asynchronously loading the views to increase the
// performance of the bundler during app loading.
// See more at: https://router.vuejs.org/guide/advanced/lazy-loading.html

const Home = () => import(/* webpackChunkName: "home" */ "@/views/Home");
const Login = () => import(/* webpackChunkName: "login" */ "@/views/Login");
const WelcomePage = () => import("@/views/WelcomePage");
const GuestSelfAssessment = () => import("@/views/GuestSelfAssessment");
const AccountArea = () => import("@/views/AccountArea");
const PasswordReset = () => import("@/views/PasswordReset.vue");
const Courses = () => import("@/views/Courses.vue");
const Nominee = () => import("@/views/Nominee.vue");
const RequestCourse = () => import("@/views/RequestCourse.vue");
const TrainingCredit = () => import("@/views/TrainingCredit.vue");
const Suggestions = () => import("@/views/Suggestions.vue");
const FutureFeatures = () => import("@/views/FutureFeatures.vue");
const SingleLearner = () => import("@/views/SingleLearner");
const CompetencyFramework = () => import("@/views/CompetencyFramework.vue");
const CustomReport = () => import("@/views/CustomReport.vue");
const AddUserData = () => import("@/views/AddUserData.vue");
const AdjustCompetencyLevels = () =>
  import("@/views/AdjustCompetencyLevels.vue");
const AdminLogin = () => import("@/views/AdminLogin");
const SignUp = () => import("@/components/Guest/SignUp.vue");
const Admin = () => import("@/views/Admin");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: true,
      learnerAuth: true,
    },
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: WelcomePage,
  },
  {
    path: "/guest-self-assessment",
    name: "GuestSelfAssessment",
    component: GuestSelfAssessment,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/password-reset/:id/:token",
    name: "PasswordReset",
    component: PasswordReset,
    meta: { requiresResetAuth: false },
  },
  {
    path: "/account",
    name: "AccountArea",
    component: AccountArea,
  },
  // {
  // 	path: "/CM",
  // 	name: "CM",
  // 	component: CM,
  // 	meta: { requiresAuth: true, cmAuth: true, learnerAuth: false },
  // },
  {
    path: "/Courses",
    name: "Courses",
    component: Courses,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: false,
      learnerAuth: false,
    },
  },
  {
    path: "/Nominee",
    name: "Nominee",
    component: Nominee,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: false,
      learnerAuth: false,
    },
  },
  {
    path: "/RequestCourse",
    name: "RequestCourse",
    component: RequestCourse,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: false,
      learnerAuth: false,
    },
  },
  {
    path: "/TrainingCreditSystem",
    name: "TrainingCreditSystem",
    component: TrainingCredit,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: true,
      learnerAuth: false,
    },
  },
  {
    path: "/Suggestions",
    name: "Suggestions",
    component: Suggestions,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: true,
      learnerAuth: false,
    },
  },
  {
    path: "/FutureFeatures",
    name: "FutureFeatures",
    component: FutureFeatures,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: true,
      learnerAuth: false,
    },
  },
  {
    path: "/learner/",
    name: "SingleLearner",
    component: SingleLearner,
    props: true,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: false,
      learnerAuth: true,
    },
  },
  {
    path: "/learner/:id",
    name: "SingleLearner",
    component: SingleLearner,
    props: true,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: false,
      learnerAuth: true,
    },
  },
  {
    path: "/competencyframework",
    name: "CompetencyFramework",
    component: CompetencyFramework,
  },
  {
    path: "/customreport",
    name: "CustomReport",
    component: CustomReport,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: true,
      learnerAuth: false,
    },
  },
  {
    path: "/adduserdata",
    name: "AddUserData",
    component: AddUserData,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: false,
      learnerAuth: false,
    },
  },
  {
    path: "/adjust-levels",
    name: "AdjustLevels",
    component: AdjustCompetencyLevels,
    meta: {
      requiresAuth: true,
      cmAuth: true,
      tfpAuth: false,
      learnerAuth: false,
    },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/admin",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/adminhome",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAdminAuth: true,
      cmAuth: false,
      tfpAuth: false,
      learnerAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

//redirect user depending on user's role
router.beforeEach((to, from, next) => {
  //translate the route name
  // const translatedName = this.$t(to.name)
  // if(translatedName) to.name = translatedName;
  if (from.name) {
    store.commit("setPreviousRoute", from.name);
  }

  //get token from local storage
  let token = localStorage.getItem("token");

  //if route is restricted to CM let them pass but redirect learner to their own page
  if (to.meta.requiresAuth) {
    ////handle no token in localStorage
    if (!token) {
      next({ path: "/login" });
    } else {
      let parsed = JSON.parse(atob(token.split(".")[1]));

      let id = parsed.user_id;
      let role = parsed.user_role;
      let country = parsed.country_id;

      // Update Vuex store with user information
      store.commit("changeRole", role);
      store.commit("changeCountry", country);
      role === 2 && store.commit("changeUser", id);

      if (role === 1 || role === 4) {
        next();
      } else {
        if (to.name === "SingleLearner") return next();
        next({ path: `/learner/${id}` });
      }
    }
  } else if (to.meta.requiresAdminAuth) {
    if (store.state.role === 3) {
      if (to.name === "Admin") return next();
      next({ path: "/adminhome" });
    } else {
      next();
    }
    //if route is not protected let everyone in
  } else {
    next();
  }
});

export default router;
