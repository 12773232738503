import { createI18n } from "vue-i18n";
import getBrowserLocale from "./utils/i18n/get-browser-locale";
import { supportedLocalesInclude } from "./utils/i18n/supported-locales";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });

  // Load translations from text directory
  const enLocale = require("./text/en").default;
  messages.en = Object.assign(messages.en || {}, enLocale);
  const itLocale = require("./text/it").default;
  messages.it = Object.assign(messages.it || {}, itLocale);
  const rusLocale = require("./text/rus").default;
  messages.rus = Object.assign(messages.rus || {}, rusLocale);
  const srbLocale = require("./text/srb").default;
  messages.srbLocale = Object.assign(messages.srb || {}, srbLocale);
  const bgLocale = require("./text/bg").default;
  messages.bgLocale = Object.assign(messages.bg || {}, bgLocale);
  //console.log({ messages });
  return messages;
}

// checks for the locale in the browser and whether the returned locale is supported. If it is, the app will appear in that language first.
// setting languageCodeOnly to true triggers the trimming process of the string returned from the browser's navigator
function getStartingLocale() {
  const browserLocale = getBrowserLocale({ languageCodeOnly: true });
  // console.log("browserLocale", browserLocale);
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || "en";
  }
}

const i18n = createI18n({
  legacy: false,
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});

export default i18n;
