import { process, processCompetencies } from "./processData";

export default {
  async resetPassword(email) {
    try {
      const response = await fetch("api/password-reset/forgot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const res = await response.json();

      return res;
    } catch (error) {
      console.log({ error });
    }
  },

  async getPasswordResetLink(email) {
    try {
      const response = await fetch("api/password-reset/forgot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const responseJson = await response.json();

      return responseJson;
    } catch (error) {
      console.log({ error });
    }
  },

  async linkIsUnused(id, token) {
    try {
      const response = await fetch(`api/password-reset/${id}/${token}`, {
        method: "GET",
      });
      const responseJson = await response.json();

      // if link is unused, the user payload will be sent
      return responseJson.payload;
    } catch (error) {
      console.log(error);
    }
  },

  async changePassword(id, token, passwordObj) {
    const { password } = passwordObj;
    try {
      const response = await fetch(`api/password-reset/${id}/${token}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Password changed successfully:", data);
        return {
          successMessage: data.successMessage,
          errorMessage: "", // Clear error message
        };
      } else {
        const errorData = await response.json();
        console.error("Failed to change password:", errorData.errorMessage);
        return {
          successMessage: "", // Clear success message
          errorMessage: errorData.errorMessage, // Get error message from server response
        };
      }
    } catch (error) {
      console.error("Error changing password:", error);
      return {
        successMessage: "", // Clear success message
        errorMessage: "Failed to change password. Please try again.", // Set generic error message
      };
    }
  },

  async changePasswordFromAccountArea(currentPassword, newPassword) {
    try {
      const response = await fetch(`api/account/change-password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          currentPassword: currentPassword,
          newPassword: newPassword,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Password changed successfully:", data);
        return {
          successMessage: data.successMessage,
          errorMessage: "", // Clear error message
        };
      } else {
        const errorData = await response.json();
        console.error("Failed to change password:", errorData.errorMessage);
        return {
          successMessage: "", // Clear success message
          errorMessage: errorData.errorMessage, // Get error message from server response
        };
      }
    } catch (error) {
      console.error("Error changing password:", error);
      return {
        successMessage: "", // Clear success message
        errorMessage: "Failed to change password. Please try again.", // Set generic error message
      };
    }
  },

  async acceptPolicy(id) {
    try {
      const response = await fetch(`api/users/accept/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseJson = await response.json();
      // console.log(responseJson);
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },

  async getPolicyStatus(id) {
    try {
      const response = await fetch(`api/users/policy/${id}`, { method: "GET" });
      const responseJson = await response.json();
      // console.log({ responseJson });
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },

  login(email, password) {
    return fetch("api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  },

  async sendLogoutTime() {
    try {
      const response = await fetch("api/auth/logout", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      // console.log(response);
      return response.json();
    } catch (error) {
      console.log({ error });
    }
  },

  async adminLogin(username, password) {
    try {
      const response = await fetch("api/admin/auth/login", {
        body: JSON.stringify({ username, password }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.json();
    } catch (error) {
      console.log({ error });
    }
  },

  async getEnrolledLearnersInCountry() {
    try {
      const response = await fetch("api/users/enrolled", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      const learners = await response.json();
      return learners;
    } catch (error) {
      console.log({ error });
    }
  },

  async getAllUsersInCountry() {
    try {
      const response = await fetch("api/users/by-country", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });

      const usersByCountry = await response.json();
      // console.log({ usersByCountry });
      return usersByCountry;
    } catch (error) {
      console.log({ error });
    }
  },

  async getAllCourses() {
    try {
      const response = await fetch("api/courses/all", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      const courses = await response.json();
      // console.log(courses);
      return courses;
    } catch (error) {
      console.log(error);
    }
  },

  async addUserToCourse({ course_id, user_id, time_completed, time_enrolled }) {
    try {
      const response = await fetch("api/enrollments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          course_id,
          user_id,
          time_completed,
          time_enrolled,
        }),
      });
      // console.log({ response });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async updateCourseCompletionDate({ time_completed, id, course_id }) {
    try {
      const response = await fetch("api/enrollments/completed", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ time_completed, id, course_id }),
      });
      // console.log({ response });
      return response.json();
    } catch (error) {
      console.log({ error });
    }
  },
  async getCoursesByCountry() {
    try {
      const response = await fetch("api/courses/country", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      const coursesByCountry = await response.json();
      // console.log({ coursesByCountry });
      return coursesByCountry;
    } catch (error) {
      console.log(error);
    }
  },

  async getCourseCategories() {
    try {
      const response = await fetch("api/course_categories", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      const categories = await response.json();
      //console.log(categories);
      return categories;
    } catch (error) {
      console.log(error);
    }
  },

  async addNewCourse(course, category) {
    try {
      const response = await fetch("api/courses/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ course, category }),
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async getProfile() {
    try {
      const response = await fetch("api/auth/profile", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async getData() {
    let learners = await (
      await fetch("api/users/", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();

    const learner_ids = learners.map(e => e.id);

    let currentLevels = await (
      await fetch(`api/competencies/levels/${learner_ids}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();

    let enrollments = await (
      await fetch("api/enrollments", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();

    let courses = await (
      await fetch("api/courses", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();

    let competencies = await (
      await fetch("api/competencies", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();

    const data = process(
      learners,
      enrollments,
      courses,
      competencies,
      currentLevels
    );
    return { data, enrollments, competencies };
  },

  // get competencies
  async getCompetencies() {
    const competencies = await (
      await fetch("api/competencies", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return competencies;
  },

  //* get competencies by country - NEED TO EDIT
  async getCompetenciesByCountry(countryId) {
    const competenciesByCountry = await (
      await fetch(`api/competencies/${countryId}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    //console.log(competenciesByPosition);
    return competenciesByCountry;
  },

  // get courses by competency
  async getCourses() {
    const courses = await (
      await fetch("api/courses", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return courses;
  },

  // get all enrollments
  async getEnrollments() {
    const enrollments = await (
      await fetch("api/enrollments", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    // console.log({ enrollments });
    return enrollments;
  },

  //get single user, process data
  async getUser(id) {
    const user = await (
      await fetch(`api/users/${id}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();

    const courses = await this.getCourses();

    let currentLevels = await (
      await fetch(`api/competencies/levels/${id}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();

    const data = processCompetencies(user, courses, currentLevels);
    return data;
  },

  async addRegion(region) {
    try {
      const res = await fetch(`api/users/addRegion`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ region }),
      });
      return res.json();
    } catch (error) {
      console.log({ error });
    }
  },

  async addRegionCoordinates(name, latitude, longitude) {
    try {
      const res = await fetch(`api/regions/addLocation`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ name, latitude, longitude }),
      });
      return res.json();
    } catch (error) {
      return console.log(error);
    }
  },

  async getLearnerLocations() {
    try {
      const res = await fetch(`api/regions`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      const locations = await res.json();
      return locations;
    } catch (error) {
      console.log(error);
    }
  },

  async getRegions() {
    try {
      const res = await fetch(`api/regions/all`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      const regions = await res.json();
      return regions;
    } catch (error) {
      console.log(error);
    }
  },

  // GET all position no token: guest
  async getAllPositions() {
    const positions = await (
      await fetch("api/positions/all", {
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return positions;
  },

  // update competency level for learner
  async updateLevel(learners, competencyId, level) {
    try {
      const res = await fetch(`api/competencies/${competencyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ learners, level }),
      });

      return res;
    } catch (error) {
      return console.log(error);
    }
  },

  async updatePositionCompetencyLevel(
    position,
    competency,
    level,
    old_level,
    pcbc_id
  ) {
    try {
      const response = await fetch(`api/positions/update-pcbc`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          position,
          competency,
          level,
          old_level,
          pcbc_id,
        }),
      });
      // console.log({ response });
      return response.json();
    } catch (error) {
      console.log({ error });
    }
  },

  //get competencies by specific position
  async getCompetenciesByPosition() {
    const competenciesByPosition = await (
      await fetch(`api/positions/competencies/`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    //console.log(competenciesByPosition);
    return competenciesByPosition;
  },

  //get competencies, positions and level required
  async getPositionCompetencyByCountry() {
    const positionCompetencyByCountry = await (
      await fetch(`api/positions/competencies-levels/`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    //console.log(competenciesByPosition);
    return positionCompetencyByCountry;
  },

  //assign competency to a position
  async assignRequiredCompetency(positionId, competencyId, levelId) {
    try {
      const res = await fetch(`api/positions/${positionId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ competencyId, levelId }),
      });
      //console.log(competencyId, levelId, positionId);
      return res;
    } catch (error) {
      return console.log(error);
    }
  },
  //delete competency & level from a position
  async deleteRequiredCompetency(positionId, competencyId, levelId) {
    try {
      const res = await fetch(`api/positions/${positionId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ competencyId, levelId }),
      });
      //console.log(competencyId, levelId, positionId);
      return res;
    } catch (error) {
      return console.log(error);
    }
  },
  //get training data by specific position
  async getTrainingDataByPosition() {
    const trainingData = await (
      await fetch(`api/courses/positions/`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return trainingData;
  },
  //get training data by specific type of training
  async getTrainingDataByType() {
    const trainingData = await (
      await fetch(`api/courses/types/`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return trainingData;
  },

  async getCourseReportByCategory(category) {
    const courseReport = await (
      await fetch(`api/courses/courseReport/${category}`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return courseReport;
  },

  async getCourseReport() {
    const courseReport = await (
      await fetch(`api/courses/courseReport/`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return courseReport;
  },

  //for CM Overview dashboard
  async getEnrolmentsByCategory() {
    const enrolmentsByCategory = await (
      await fetch(`api/enrollments/by-category/`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return enrolmentsByCategory;
  },

  async getTailoredCoursesByPosition() {
    const tailoredCourses = await (
      await fetch(`api/courses/tailoredCourses/`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return tailoredCourses;
  },

  async getTailoredCoursesCount() {
    const tailoredCoursesCount = await (
      await fetch(`api/courses/tailoredCoursesCount/`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return tailoredCoursesCount;
  },

  async getPositionsByCountry() {
    const positions = await (
      await fetch(`api/positions`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return positions;
  },

  async getRegionsByCountry(country) {
    const response = await fetch(
      `/api/regions/regionsByCountry?selected_country=${encodeURIComponent(
        country
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch regions");
    }
    return await response.json();
  },

  async addSingleUser(firstname, lastname, email, position, region) {
    const body = {
      firstname,
      lastname,
      email,
      position,
    };
    if (region !== "") {
      body.region = region;
    }
    return fetch("api/users/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    })
      .then(response => {
        // console.log({ response });
        return response.json();
      })
      .catch(error => console.log(error));
  },

  async signUpUser(firstname, lastname, email, position, country, region) {
    const body = {
      firstname,
      lastname,
      email,
      position,
      country,
      region,
    };

    try {
      const response = await fetch("api/guest-user/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      return { status: response.status, data };
    } catch (error) {
      console.log(error);
    }
  },

  async addMultipleUsers(users) {
    try {
      const response = await fetch("api/users/add-multiple", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ users }),
      });
      const data = await response.json();
      console.log("res", { data });
      return data;
    } catch (error) {
      console.log(error);
    }
  },

  async getAdminProfile() {
    try {
      const response = await fetch("api/admin/auth/profile", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      return console.log(error);
    }
  },

  async addCountry(country_name, region, member_nation) {
    return fetch("api/admin/countries/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ country_name, region, member_nation }),
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  },

  async addCompetency(competency) {
    return fetch("api/admin/competencies/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ competency }),
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  },

  async getCountries() {
    const countries = await (
      await fetch(`api/admin/countries/all`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return countries;
  },

  // db countries for guests
  async allCountries() {
    const countries = await (
      await fetch(`api/countries/all`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return countries;
  },

  async globalData() {
    const countries = await (
      await fetch(`api/countries/world-data`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return countries;
  },

  async addUser(
    firstname,
    lastname,
    email,
    region,
    position,
    country_name,
    role
  ) {
    const body = {
      firstname,
      lastname,
      email,
      region,
      position,
      country_name,
      role,
    };
    if (region !== "") {
      body.region = region;
    }
    return fetch("api/admin/users/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  },

  async addMultipleUsersAdmin(users, country) {
    return fetch("api/admin/users/add-multiple", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ users, country }),
    })
      .then(response => {
        // console.log({ response });
        return response.json();
      })
      .catch(error => {
        console.log(error);
        return { error };
      });
  },

  async addCountryProgram(
    //framework,
    category,
    competency,
    level,
    country,
    bundle
  ) {
    return fetch("api/admin/programs/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        //framework,
        category,
        competency,
        level,
        country,
        bundle,
      }),
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  },

  async addPosition(position, country) {
    return fetch("api/admin/positions/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ position, country }),
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  },

  async addPositionCompetencyByCountry(country, position, competency, level) {
    return fetch("api/admin/positions/addPositionCompetency", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ country, position, competency, level }),
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  },

  async getCountryPositions() {
    const positions = await (
      await fetch("api/admin/positions", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return positions;
  },

  async getPositions() {
    const positions = await (
      await fetch("api/admin/positions/all", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return positions;
  },

  async getAllCompetencies() {
    const competencies = await (
      await fetch(`api/admin/competencies`, {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return competencies;
  },

  // get all competencies in guest mode
  async fetchAllCompetencies() {
    const competencies = await (
      await fetch(`api/competencies/all`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return competencies;
  },

  // get all competencies in guest mode
  async guestCompetenciesByCountry(country_id) {
    const competencies = await (
      await fetch(`api/competencies/guest/${country_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    return competencies;
  },

  async getAllLevels() {
    const levels = await (
      await fetch("api/admin/levels", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return levels;
  },

  async getPrograms() {
    const programs = await (
      await fetch("api/admin/programs", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return programs;
  },

  async getRoles() {
    const roles = await (
      await fetch("api/admin/roles", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return roles;
  },

  async getUsers() {
    const users = await (
      await fetch("api/admin/users", {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return users;
  },

  async saveScore(competency, level) {
    return fetch("api/self_assessment/save-score", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ competency, level }),
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  },

  // guest score from guest self-assessment/registered user
  async saveGuestScores(scores, userId, countryId) {
    return fetch("api/self_assessment/save-guest-scores", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ scores, userId, countryId }),
    });
  },

  async getScores(id) {
    try {
      const response = await fetch(`api/self_assessment/${id}`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async getCountryCredits() {
    const countryCredits = await (
      await fetch("api/training_credits/credits", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    return countryCredits;
  },
  async getCalendarEvents(url) {
    try {
      const response = await fetch(url, {
        method: "GET",
      });

      const responseJson = await response.json();

      //console.log(responseJson)
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  },

  async uploadLanguageCertificate(formData) {
    try {
      const response = await fetch("api/language_certificates/add", {
        method: "POST",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
        body: formData,
      });
      return response.json();
    } catch (error) {
      console.log({ error });
    }
  },

  async getUserCertificates(id) {
    try {
      const response = await fetch(`api/language_certificates/${id}`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      return console.log(error);
    }
  },
  async deleteLearnerCertificate(file_id) {
    try {
      const response = await fetch(`api/language_certificates/${file_id}`, {
        method: "DELETE",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      return console.log(error);
    }
  },

  //get competencies by country - admin
  // async getCompetenciesByCountry(countryId) {
  // 	const competenciesByCountry = await (
  // 		await fetch(`api/admin/competencies/${countryId}`, {
  // 			headers: {
  // 				"x-access-token": localStorage.getItem("token"),
  // 			},
  // 		})
  // 	).json();
  // 	//console.log(competenciesByPosition);
  // 	return competenciesByCountry;
  // },
};

// async getCredits() {
//   try {
//     const response = await fetch("api/users/credits", {
//       method: "GET",
//       headers: {
//         "x-access-token": localStorage.getItem("token"),
//       },
//     });
//     return await response.json();
//   } catch (error) {
//     return console.log(error);
//   }
// },
