//import { groupBy } from "../utils/helpers";

// competency levels mapping
const levelNames = {
  1: "Awareness",
  2: "Beginner",
  3: "Competent",
  4: "Proficient",
  5: "Expert",
};

// process learner data
export function process(
  learners,
  enrollments,
  courses,
  competencies,
  currentLevels
) {
  //const enrollmentsByLearner = groupBy(enrollments, "user_id");
  learners.forEach((learner) => {
    //learner.enrollment = [{ ...enrollments[learner.id] }];

    //filtered by the id of the current learner in the loop
    learner.enrollments = enrollments
      .map((a) => {
        return { ...a };
      })
      .filter((e) => e.user_id === learner.id);

    // store competencies this learner needs
    learner.competencies = competencies[learner.position]
      ? competencies[learner.position].map((e) => ({ ...e }))
      : [];

    // check completed courses & categories to determine current level on competency
    learner.competencies.forEach((competency) => {
      // let completedLevels = [];
      // let current_level = 0;
      competency.levels = {};

      for (let level in courses[competency.name]) {
        if (+level <= competency.required_level || +level <= 5) {
          let levelProgress = 0;
          let bundleCourses = new Map();

          // check all required courses of a level
          Object.keys(courses[competency.name][level]).forEach((bundle) => {
            courses[competency.name][level][bundle].forEach((course) => {
              // let enrollment = learner.enrollments.find(
              //   (e) => e.course_id === course.id
              // );
              let enrollment = learner.enrollments.find(
                (e) => e.category_id === course.category_id
              );

              if (enrollment) {
                //TO DO - how can we do this better?

                //for Epi
                if (course.competency === "Epidemiology") {
                  //level 2 epi - 1 bundle
                  if (course.level === 2) {
                    if (
                      course.category_id === 1 ||
                      course.category_id === 2 ||
                      course.category_id === 3 ||
                      course.category_id === 5 ||
                      course.category_id === 11
                    ) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }

                  //for level 3 epi - 2 bundles
                  else if (course.level === 3) {
                    if (course.bundleCode === 1) {
                      if (
                        course.category_id === 1 ||
                        course.category_id === 2 ||
                        course.category_id === 3 ||
                        course.category_id === 5
                      ) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 2) {
                      if (course.category_id === 11) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (bundleCourses.has(1) && bundleCourses.has(2)) {
                      levelProgress = 100;
                    }
                  }

                  //for level 4 epi - 4 bundles
                  else if (course.level === 4) {
                    if (course.bundleCode === 1) {
                      if (course.category_id === 8) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 2) {
                      if (course.category_id === 13) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 3) {
                      if (course.category_id === 24) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 4) {
                      if (course.category_id === 14) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (
                      bundleCourses.has(1) &&
                      bundleCourses.has(2) &&
                      bundleCourses.has(3) &&
                      bundleCourses.has(4)
                    ) {
                      levelProgress = 100; // all 4 bundles completed
                    }
                  }
                } else if (
                  course.competency === "Transboundary Animal Disease" &&
                  learner.country === "Switzerland"
                ) {
                  if (course.level === 1) {
                    if (course.category_id === 15) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 2) {
                    if (course.category_id === 1 || course.category_id === 3) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 3) {
                    if (course.category_id === 11) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 4) {
                    if (course.category_id === 11) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                }

                //for Transb
                else if (
                  course.competency === "Transboundary Animal Diseases"
                ) {
                  //for level 1 trans - 1 bundle
                  if (course.level === 1) {
                    if (
                      course.category_id === 1 ||
                      course.category_id === 2 ||
                      course.category_id === 3 ||
                      course.category_id === 5 ||
                      course.category_id === 11
                    ) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }

                  // for level 2 trans - 2 bundles
                  else if (course.level === 2) {
                    if (course.bundleCode === 1) {
                      if (
                        course.category_id === 1 ||
                        course.category_id === 3
                      ) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 2) {
                      if (
                        course.category_id === 2 ||
                        course.category_id === 5
                      ) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (bundleCourses.has(1) && bundleCourses.has(2)) {
                      levelProgress = 100;
                    }
                  }

                  // for level 3 trans - 6 bundles
                  else if (course.level === 3) {
                    if (course.bundleCode === 1) {
                      if (
                        course.category_id === 1 ||
                        course.category_id === 3
                      ) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 2) {
                      if (course.category_id === 2) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 3) {
                      if (course.category_id === 5) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 4) {
                      if (course.category_id === 12) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 5) {
                      if (course.category_id === 14) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 6) {
                      if (course.category_id === 11) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (
                      bundleCourses.has(1) &&
                      bundleCourses.has(2) &&
                      bundleCourses.has(3) &&
                      bundleCourses.has(4) &&
                      bundleCourses.has(5) &&
                      bundleCourses.has(6)
                    ) {
                      levelProgress = 100;
                    }
                  }

                  // for level 4 trans - 1 bundle
                  else if (course.level === 4) {
                    if (course.category_id === 13) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                } else if (
                  course.competency === "Transboundary Animal Disease"
                ) {
                  if (course.level === 1) {
                    if (course.category_id === 15) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 2) {
                    if (course.category_id === 1 || course.category_id === 3) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 3) {
                    if (course.category_id === 11) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 4) {
                    if (course.category_id === 11) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                } else if (course.competency === "Animal Welfare") {
                  if (
                    course.level === 1 ||
                    course.level === 3 ||
                    course.level === 4
                  ) {
                    if (
                      course.category_id === 20 &&
                      course.course ===
                        "Depopulation, Disposal and Cleaning and Disinfection – From Theory to Practice"
                    ) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                } else if (course.competency === "Emergency Preparedness") {
                  if (course.level === 1) {
                    if (
                      course.category_id === 1 ||
                      course.category_id === 2 ||
                      course.category_id === 3 ||
                      course.category_id === 5 ||
                      course.category_id === 10
                    ) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                  if (course.level === 4) {
                    if (course.category_id === 10) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                  if (course.level === 5) {
                    if (course.category_id === 30) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                } else if (course.competency === "Risk Analysis") {
                  if (course.level === 4) {
                    if (course.category_id === 25) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                }
              }
            });

            competency.levels[level] = {
              levelProgress: levelProgress,
            };
          }); // each bundle
        }
      }

      let completedLevels = [];
      let current_level = 0;

      for (let level in competency.levels) {
        if (competency.levels[level].levelProgress < 100) break;
        completedLevels.push(level);
      }

      // store highest completed level as current level
      if (completedLevels.length) current_level = Math.max(...completedLevels);

      // check if there is a manually set level & if it's higher
      const setLevel = currentLevels.find(
        (e) => e.learner_id === learner.id && e.competency_id === competency.id
        //&& e.current_level > current_level
      );

      //log level before checking for manually set level
      let prevLevel = current_level; //change variable name

      // store current level on competency
      competency.current_level = setLevel
        ? setLevel.current_level
        : current_level;

      //set level progress to 100 if manually set level exists
      for (let level in competency.levels) {
        if (
          competency.current_level > prevLevel &&
          level <= competency.current_level
        ) {
          //competency.levels[competency.current_level].levelProgress = 100
          competency.levels[level].levelProgress = 100;
        }
      }
    });
  });

  return learners;
}

// calculate current level on competencies
export function processCompetencies(learner, courses, currentLevels) {
  if (Object.keys(learner.selfAssessments).length !== 0) {
    learner.selfAssessments.forEach((e) => {
      if (e.self_current_level === null) e.self_current_level = 0;
    });
  }

  console.log(learner.selfAssessments);
  learner.competencies.forEach((competency) => {
    // store number of enrolled courses on competency
    competency.enrolled_courses = 0;
    competency.levels = {};
	if (courses.length) {
    // store all courses associated with each required competency and level and bundle
    Object.keys(courses[competency.name]).forEach((level) => {
      // store courses at or below required level
      //if (+level <= competency.required_level && +level <= 5) {
      if (+level <= competency.required_level || +level <= 5) {
        let levelProgress = 0;
        let bundleCourses = new Map(); //map of bundles and course category id

  
          //for each bundle
          Object.keys(courses[competency.name][level]).forEach((bundle) => {
            // check if learner is enrolled in required courses and store progress
            courses[competency.name][level][bundle].forEach((course) => {
              let enrollment = learner.enrollments.find(
                (e) => e.category_id === course.category_id
              );

              // store progress on course
              if (enrollment) {
                course.enrolled = 1;
                course.progress = enrollment.progress;
                course.time_enrolled = enrollment.time_enrolled;
                course.time_completed = enrollment.time_completed;
                course.status = enrollment.status;
                competency.enrolled_courses++;

                // store progress on level (comes from here) -- needs to be conditionals because of different scenarios
                //progress += enrollment.progress;

                // level progress
                //TO DO - how can we do this better?
                //for Epi
                if (course.competency === "Epidemiology") {
                  //level 2 epi - 1 bundle
                  if (course.level === 2) {
                    if (
                      course.category_id === 1 ||
                      course.category_id === 2 ||
                      course.category_id === 3 ||
                      course.category_id === 5 ||
                      course.category_id === 11
                    ) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }

                  //for level 3 epi - 2 bundles
                  else if (course.level === 3) {
                    if (course.bundleCode === 1) {
                      if (
                        course.category_id === 1 ||
                        course.category_id === 2 ||
                        course.category_id === 3 ||
                        course.category_id === 5
                      ) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 2) {
                      if (course.category_id === 11) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (bundleCourses.has(1) && bundleCourses.has(2)) {
                      levelProgress = 100;
                    }
                  }

                  //for level 4 epi - 4 bundles
                  else if (course.level === 4) {
                    if (course.bundleCode === 1) {
                      if (course.category_id === 8) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 2) {
                      if (course.category_id === 13) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 3) {
                      if (course.category_id === 24) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 4) {
                      if (course.category_id === 14) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (
                      bundleCourses.has(1) &&
                      bundleCourses.has(2) &&
                      bundleCourses.has(3) &&
                      bundleCourses.has(4)
                    ) {
                      levelProgress = 100; // all 4 bundles completed
                    }
                  }
                } else if (
                  course.competency === "Transboundary Animal Disease" &&
                  learner.country === "Switzerland"
                ) {
                  if (course.level === 1) {
                    if (course.category_id === 15) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 2) {
                    if (course.category_id === 1 || course.category_id === 3) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 3) {
                    if (course.category_id === 11) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  } else if (course.level === 4) {
                    if (course.category_id === 11) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                }

                //for Transb
                else if (
                  course.competency === "Transboundary Animal Diseases"
                ) {
                  //for level 1 trans - 1 bundle
                  if (course.level === 1) {
                    if (
                      course.category_id === 1 ||
                      course.category_id === 2 ||
                      course.category_id === 3 ||
                      course.category_id === 5 ||
                      course.category_id === 11
                    ) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }

                  // for level 2 trans - 2 bundles
                  else if (course.level === 2) {
                    if (course.bundleCode === 1) {
                      if (
                        course.category_id === 1 ||
                        course.category_id === 3
                      ) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (course.bundleCode === 2) {
                      if (
                        course.category_id === 2 ||
                        course.category_id === 5
                      ) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (bundleCourses.has(1) && bundleCourses.has(2)) {
                      levelProgress = 100;
                    }
                  }

                  // for level 3 trans - 6 bundles
                  else if (course.level === 3) {
                    if (course.bundleCode === 1) {
                      if (
                        course.category_id === 1 ||
                        course.category_id === 3
                      ) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 2) {
                      if (course.category_id === 2) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 3) {
                      if (course.category_id === 5) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 4) {
                      if (course.category_id === 12) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 5) {
                      if (course.category_id === 14) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }
                    if (course.bundleCode === 6) {
                      if (course.category_id === 11) {
                        if (enrollment.status.toLowerCase() === "completed") {
                          bundleCourses.set(
                            course.bundleCode,
                            course.category_id
                          );
                        }
                      }
                    }

                    if (
                      bundleCourses.has(1) &&
                      bundleCourses.has(2) &&
                      bundleCourses.has(3) &&
                      bundleCourses.has(4) &&
                      bundleCourses.has(5) &&
                      bundleCourses.has(6)
                    ) {
                      levelProgress = 100;
                    }
                  }

                  // for level 4 trans - 1 bundle
                  else if (course.level === 4) {
                    if (course.category_id === 13) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                } else if (course.competency === "Animal Welfare") {
                  if (
                    course.level === 1 ||
                    course.level === 3 ||
                    course.level === 4
                  ) {
                    if (
                      course.category_id === 20 &&
                      course.course ===
                        "Depopulation, Disposal and Cleaning and Disinfection – From Theory to Practice"
                    ) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                } else if (course.competency === "Emergency Preparedness") {
                  if (course.level === 1) {
                    if (
                      course.category_id === 1 ||
                      course.category_id === 2 ||
                      course.category_id === 3 ||
                      course.category_id === 5 ||
                      course.category_id === 10
                    ) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                  if (course.level === 4) {
                    if (course.category_id === 10) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                  if (course.level === 5) {
                    if (course.category_id === 30) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                } else if (course.competency === "Risk Analysis") {
                  if (course.level === 4) {
                    if (course.category_id === 25) {
                      if (enrollment.status.toLowerCase() === "completed") {
                        levelProgress = 100;
                      }
                    }
                  }
                }
              } else {
                course.enrolled = 0; // 1 is true, 0 is false
              }
            });

            // store course bundles and progress on each level
            competency.levels[level] = {
              bundles: Object.values(courses[competency.name][level]),
              //courses: courses[competency.name][level][bundle],
              levelProgress: levelProgress,
              name: levelNames[level],
              levelNumber: level,
            };
          }); //for each bundle
        
      }
    });
	}
    // determine current level in competency
    let completedLevels = [];
    let current_level = 0;

    for (let level in competency.levels) {
      if (competency.levels[level].levelProgress < 100) break;
      completedLevels.push(level);
    }

    if (completedLevels.length) current_level = Math.max(...completedLevels);

    // check if there is a manually set level & if it's higher
    // const setLevel = currentLevels.find(
    // 	(e) => e.learner_id === learner.id && e.competency_id === competency.id
    // );

    //console.log(learner.selfAssessments);
    // new set level - 02-11-22
    let setNewLevel = 0;
    if (learner.selfAssessments.length != 0) {
      setNewLevel = learner.selfAssessments.find(
        (e) => e.competency_id === competency.id
      );
    } else {
      setNewLevel = currentLevels.find(
        (e) => e.learner_id === learner.id && e.competency_id === competency.id
      );
    }

    //log level before checking for manually set level
    let prevLevel = current_level; //change variable name

    // store current level on competency
    // competency.current_level = setLevel
    // 	? setLevel.current_level
    // 	: current_level;
    competency.current_level = setNewLevel
      ? setNewLevel.self_current_level
      : current_level;

    //set level progress to 100 if manually set level exists
    for (let level in competency.levels) {
      if (
        competency.current_level > prevLevel &&
        level <= competency.current_level
      ) {
        //competency.levels[competency.current_level].levelProgress = 100
        competency.levels[level].levelProgress = 100;
      }
    }

    // store status of competency
    competency.status =
      competency.current_level >= competency.required_level
        ? "Completed"
        : "Open";
  });

  return learner;
}
