<template>
  <!-- Primary nav -->
  <nav class="w-screen" @click="handleOutsideClick">
    <div
      class="flex justify-between items-center text-gray-50 bg-green-primary px-6 py-1 md:py-0 z-50"
    >
      <font-awesome-icon
        name="open"
        icon="bars"
        @click.stop="openSideBar"
        class="cursor-pointer text-lg"
        v-if="!toggled && this.$store.state.role !== 2 && auth"
      />
      <div class="pl-4">
        <router-link to="/">
          <span class="font-light">
            <img
              class="w-16 md:w-28 h-auto my-2 md:my-4"
              src="/static/assets/EuFMD_white.png"
              alt="EuFMD logo"
            />
          </span>
        </router-link>
      </div>

      <!-- Spacer to push the user link to the far right -->
      <div class="flex-grow"></div>

      <!-- Profile icon and sidebar -->
      <div class="relative" v-if="auth || adminAuth" ref="userProfile">
        <font-awesome-icon
          icon="user-circle"
          class="cursor-pointer text-2xl"
          @click="toggleProfileSidebar"
        />
        <div
          v-if="isProfileSidebarOpen"
          class="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-50"
        >
          <div class="p-4 text-gray-800">
            <p class="font-semibold">{{ username }}</p>
            <p v-show="this.$store.state.role !== 3" class="text-sm">
              <span v-show="region !== 'null' && country"> - </span>
              {{ country || "" }}
            </p>
            <router-link to="/account" class="text-sm hover:underline">
              {{ $t("account.viewAccount") }}
            </router-link>
            <router-link to="/login">
              <button
                @click="logOut"
                class="mt-4 px-4 py-2 w-full text-left text-sm bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none"
              >
                <font-awesome-icon icon="sign-out-alt" class="mr-2" />
                {{ $t("account.logOut") }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Secondary nav -->
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-7 bg-green-tom"
    >
      <router-link
        to="/welcome"
        class="col-span-1 flex items-center justify-center p-4 font-light text-xl text-gray-100 shadow-sm hover:text-gray-50 hover:bg-green-800 focus:outline-none transition duration-300 ease-in-out"
        :class="{
          '': activeTab === 'home',
          'hover:bg-green-900': activeTab !== 'home',
        }"
      >
        {{ $t("routes.welcome") }}
      </router-link>

      <router-link
        to="/guest-self-assessment"
        v-if="!isLoggedIn"
        class="col-span-1 flex items-center justify-center p-4 font-light text-xl text-gray-100 shadow-sm hover:text-gray-50 hover:bg-green-800 focus:outline-none transition duration-300 ease-in-out"
        :class="{
          '': activeTab === 'home',
          'hover:bg-green-900': activeTab !== 'guest-self-assessment',
        }"
        @click.stop="clearAssessmentData"
      >
        {{ $t("singleLearner.self_assessment") }}
      </router-link>

      <div
        v-if="hideOnSmall"
        class="hidden lg:block lg:col-span-1 xl:col-span-3"
      ></div>
      <div
        v-if="hideOnSmall && isLoggedIn"
        class="hidden lg:block lg:col-span-1 xl:col-span-2"
      ></div>

      <router-link
        to="/login"
        v-if="!isLoggedIn"
        class="col-span-1 flex items-center justify-center p-4 font-light text-xl shadow-sm text-gray-100 hover:text-gray-50 hover:bg-green-800 focus:outline-none transition duration-300 ease-in-out"
        :class="{
          '': activeTab === 'login',
          'hover:bg-green-900': activeTab !== 'Login',
        }"
      >
        {{ $t("routes.loginRegister") }}
      </router-link>

      <div class="col-span-1">
        <locale-switcher />
      </div>
    </div>

    <div
      v-show="$route.name !== 'Login' && $route.name !== 'SignUp'"
      class="w-screen bg-white p-3 text-green-primary"
    >
      <div class="flex space-x-8 items-center px-6 md:px-24">
        <p class="font-light">
          <span>
            <div>
              <span class="font-black text-xl mr-3 font-martaBold">TOM</span
              ><span class="font-light mr-2 font-marta">
                EuFMD training management system</span
              ><span class="mr-2">|</span
              ><span class="font-light font-marta">Pilot version</span>
            </div>
          </span>
        </p>
      </div>
    </div>
  </nav>
</template>

<script>
import api from "@/services/data";
import LocaleSwitcher from "./LocaleSwitcher.vue";
import store from "../store";

export default {
  components: { LocaleSwitcher },

  name: "Navbar",

  props: ["toggled", "auth", "adminAuth"],

  data() {
    return {
      username: "",
      country: "",
      region: "",
      route: "",
      showModal: false,
      hideOnSmall: true,
      isProfileSidebarOpen: false,
    };
  },

  computed: {
    activeTab() {
      switch (this.$route.path) {
        case "/welcome":
          return "home";
        case "/guest-self-assessment":
          return "guest-self-assessment";
        default:
          return "home";
      }
    },

    isLoggedIn() {
      return store.state.isLoggedIn;
    },
  },

  methods: {
    clearAssessmentData() {
      this.$store.commit("clearSelectedCountry");
      this.$store.commit("clearScores");
    },

    showHelpModal() {
      this.showModal = !this.showModal;
    },

    logOut() {
      // Notify parent component (App) of logout
      this.isProfileSidebarOpen = false;
      this.$store.state.isLoggedIn = false;

      if (this.$store.state.role === 1 || this.$store.state.role === 2) {
        this.$emit("auth", false);
        this.$emit("closeRegionModal");
      } else if (this.$store.state.role === 3) {
        this.$emit("adminAuth", false);
      }

      api.sendLogoutTime();
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("auth");
      localStorage.removeItem("adminAuth");
    },

    toggleProfileSidebar() {
      this.isProfileSidebarOpen = !this.isProfileSidebarOpen;
    },

    // Close user profile on window click
    handleOutsideClick() {
      const userProfile = this.$refs.userProfile;
      if (userProfile && !userProfile.contains(event.target)) {
        this.isProfileSidebarOpen = false;
      }
    },

    // Fetch user data that displays in the navbar
    async getData() {
      let token = localStorage.getItem("token");
      let parsed = JSON.parse(atob(token.split(".")[1]));
      let role = parsed.user_role;

      if (role === 1 || role === 2 || role === 4) {
        const res = await api.getProfile();
        this.username = res.fullname;
        this.country = res.country;
        this.region = res.region;
      } else if (role === 3) {
        this.username = "TOM Admin";
        this.country = " ";
      }
    },

    openSideBar() {
      this.$emit("sideBarToggled", true);
    },

    processRoute(str) {
      return (
        str.charAt(0).toUpperCase() +
        this.$route.name.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2")
      );
    },
  },

  created() {
    (this.auth || this.adminAuth) && this.getData();
  },

  mounted() {
    window.addEventListener("click", this.handleOutsideClick);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.handleOutsideClick);
  },

  watch: {
    $route() {
      // Change routename on breadcrumbs navigation
      let currentRoute = this.$route.name;
      this.route =
        currentRoute == "SingleLearner"
          ? this.$t("routes.Learner Profile")
          : this.processRoute(currentRoute);
    },
    auth: function () {
      this.getData();
    },
    adminAuth: function () {
      this.getData();
    },
  },
};
</script>

<style></style>
