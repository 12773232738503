import { createStore } from "vuex";
import api from "@/services/data";

export default createStore({
  state() {
    return {
      role: "",
      id: "",
      country: "",
      countryName: "",
      selectedCountry: "",
      isLoggedIn: false,
      countryCities: [],
      competencies: [],
      savedScores: [],
      loading: false,
      previousRoute: "",
      showAssessmentRegistration: false,
      showAssessmentLogin: false,
    };
  },
  mutations: {
    setIsLoggedIn(state, value) {
      state.isLoggedIn = value;
    },

    changeRole(state, role) {
      state.role = role;
    },

    changeUser(state, id) {
      state.id = id;
    },

    changeCountry(state, country) {
      state.country = country;
    },

    changeSelectedCountry(state, countryName) {
      state.selectedCountry = countryName;
    },

    clearSelectedCountry(state) {
      state.selectedCountry = "";
    },

    changeCountryName(state, countryName) {
      state.countryName = countryName;
    },

    addCountryCities(state, countryCities) {
      state.countryCities = countryCities;
    },

    SET_COMPETENCIES(state, competencies) {
      state.competencies = competencies;
    },

    SET_LOADING(state, loading) {
      state.loading = loading;
    },

    setPreviousRoute(state, routeName) {
      state.previousRoute = routeName;
    },

    UPDATE_SCORES(state, { competencyName, level }) {
      state.savedScores.push({
        name: competencyName,
        level: level,
      });
      localStorage.setItem(
        "guestSavedScores",
        JSON.stringify(state.savedScores)
      );
    },

    clearScores(state) {
      state.savedScores = [];
    },

    SET_SHOW_SIGNUP(state, value) {
      state.showAssessmentRegistration = value;
    },
    SET_SHOW_LOGIN(state, value) {
      state.showAssessmentLogin = value;
    },
  },

  actions: {
    async getAllCompetencies({ commit }) {
      commit("SET_LOADING", true);
      try {
        const allCompetencies = await api.fetchAllCompetencies();

        const competenciesWithCompletionStatus = allCompetencies.map(
          competency => ({
            ...competency,
            isCompleted: false,
          })
        );

        commit("SET_COMPETENCIES", competenciesWithCompletionStatus);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      commit("SET_LOADING", false);
    },

    async getCountryCompetencies({ state, commit }) {
      commit("SET_LOADING", true);
      try {
        const allCountriesData = await api.globalData();
        const selectedCountry = state.selectedCountry;

        const selected = allCountriesData.find(
          country => country.name === selectedCountry
        );

        if (selected) {
          const country_id = selected.id;
          const data = await api.guestCompetenciesByCountry(country_id);
          if (data.length > 0) {
            const competenciesWithCompletionStatus = data.map(competency => ({
              ...competency,
              isCompleted: false,
            }));

            commit("SET_COMPETENCIES", competenciesWithCompletionStatus);
          } else {
            await this.dispatch("getAllCompetencies");
          }
        } else {
          await this.dispatch("getAllCompetencies");
        }
      } catch (error) {
        console.error("Error retrieving country competencies:", error);
      }
      commit("SET_LOADING", false);
    },
  },
});
