<template>
  <div class="w-full relative inline-block hover:bg-green-800">
    <div>
      <select
        class="w-full text-center bg-green-tom px-4 py-4 pr-6 font-light text-xl shadow-sm text-gray-100 appearance-none hover:text-gray-50 focus:outline-none leading-tight transition duration-300 ease-in-out cursor-pointer"
        v-model="selectedLocale"
        @change="updateLocale"
      >
        <option
          v-for="locale in locales"
          :key="locale.code"
          :value="locale.code"
        >
          {{ supportedLanguages[locale.name.toLowerCase()] }}
        </option>
      </select>
    </div>
    <div
      class="flex items-center font-light text-xl text-gray-100 pointer-events-none absolute inset-y-0 right-0 pr-32 md:pr-16 lg:pr-12"
    >
      <!-- Custom caret -->
      <span>&#9660;</span>
    </div>
  </div>
</template>

<script>
import { getSupportedLocales } from "../utils/i18n/supported-locales";
import { supportedLanguages } from "../constants/languages";

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      selectedLocale: this.$i18n.fallbackLocale,
      locales: getSupportedLocales(),
      supportedLanguages,
    };
  },
  methods: {
    updateLocale() {
      this.$i18n.locale = this.selectedLocale;
    },
  },
};
</script>

<style></style>
