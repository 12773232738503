<template>
  <div
    class="modal fixed w-full h-full top-0 left-0 flex items-center z-50 justify-center"
  >
    <div class="absolute w-full h-full bg-gray-900 opacity-50"></div>

    <div
      class="bg-white min-w-min mx-auto rounded shadow-lg z-50 overflow-y-auto"
    >
      <div class="py-4 text-left px-6">
        <div class="flex justify-end items-center">
          <font-awesome-icon
            class="cursor-pointer z-50 hover:text-green-forest"
            icon="times"
            @click="closeModal"
          />
        </div>
        <h1 class="font-bold text-center my-5">
          {{ $t("account.timeout") }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoutModal",
  data() {
    return {};
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style></style>
