export default {
  "welcome": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добре дошли в TOM"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOM е система за управление на обучението, която позволява на страните да наблюдават индивидуалното обучение и развитие на своите ветеринарни професионалисти и да подобряват ветеринарния капацитет. Тя служи като рамка за компетентност, предлагаща признание на ветеринарите, и функционира като уеб-базирано средство за мониторинг на завършването на обучението в рамките на ветеринарните услуги."])}
  },
  "account": {
    "viewAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виж акаунт"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изход"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текуща парола"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова парола"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потвърди новата парола"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуализирай паролата"])},
    "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ктуализиране..."])},
    "timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вие бяхте изключени след 15 минути неактивност."])}
  },
  "sidebar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на потребителски данни"])},
    "adjust_competency_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка на нива на компетентност"])},
    "custom_reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализирани отчети"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсове"])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рамка на компетентност"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система за кредитиране на обучение"])},
    "nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кандидат"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка за персонализиран курс"])},
    "future_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бъдещи функции"])},
    "leave_a_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оставете предложение"])}
  },
  "cmHome": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед"])},
    "all_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички категории"])},
    "select_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете категория, за да филтрирате графиките по-долу."])},
    "select_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сега изберете курс, за да филтрирате графиките по-долу."])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете страна"])},
    "bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниво"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички курсове"])},
    "add_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добави програма"])},
    "competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетентност"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Погледни картата на страната"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Границите и имената, показани на тази карта, както и обозначенията, използвани на нея, не предполагат изразяване на каквото и да е мнение от страна на ФАО относно правния статус на която и да е страна, територия, град или район или на нейните власти, нито относно делимитацията на нейните граници. Точковите или прекъснатите линии на картите представляват приблизителни граници, за които може все още да няма пълно споразумение."])},
    "coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очаквайте скоро"])},
    "learners_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрирани участници"])},
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници"])},
    "participants_courses_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус на курсовете на участниците"])},
    "tailored_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Национални курсове FEPC"])},
    "real_time_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници в обучение в реално време"])},
    "workshop_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници в работилница"])},
    "online_course_participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници в онлайн курсове"])},
    "courses_by_status_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсове по статус"])},
    "courses_by_role_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завършени курсове по роля"])},
    "courses_by_year_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завършени курсове по година"])},
    "training_credit_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система за кредити за обучение"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завършено"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процес"])},
    "request_a_tailored_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка"])},
    "average_number_of_courses_per_participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среден брой курсове на участник"])},
    "compentencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетенции"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете"])},
    "select_a_position_to_see_competencies_related_to this_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете позиция, за да видите компетенциите, свързани с тази роля"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете позиция"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете ниво"])},
    "select_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете регион"])},
    "select_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете компетенция"])},
    "assign_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присвойте ниво"])},
    "learners_in_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо участници: "])},
    "learners_with_this_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участници с тази позиция: "])},
    "overview_of_required_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на необходимите компетенции"])},
    "select_a_position_above_to_see_the_competency_levels_of_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете позиция по-горе, за да видите нивата на компетенция на участниците в тази позиция."])},
    "competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детайли за компетенцията"])},
    "learn_more_about_the_competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научете повече за рамката на компетенциите"])},
    "select_a_position_to_see_competency_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете позиция по-горе, за да видите детайлите на компетенцията за тази позиция."])},
    "number_of_learners_per_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой участници по компетенция"])},
    "click_to_view_more_details_chart_on_the_right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кликнете върху компетенция, за да видите повече детайли. Графика вдясно."])},
    "you_are_seeing_data_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виждате данни за"])},
    "Epidemiology_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Епидемиологията е изучаване на факторите, които определят и влияят на честотата и разпределението на заболявания или други здравни събития и техните причини в определена популация с цел контрол на тяхното развитие и разпространение и установяване на програми за предотвратяване на развитието и разпространението."])},
    "TADS_explained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TADS са заболявания, които са силно заразни или предавани и имат потенциал за много бързо разпространение, независимо от националните граници. Профилирането на заболяването се отнася до клиничните признаци, етиология, патогенеза, клиничен хо��, потенциал за предаване и епидемиология на заболяването. Контролът и превенцията са част от компетенцията за контрол и превенция на заболяванията. TAD включват африканска чума по свинете, силно патогенна птича инфлуенца, заразна плевропневмония при говеда, хеморагична септицемия, инфлуенца А, чума по говедата, треска от рифтовата долина, коронавирус на респираторния синдром на Близкия изток и болест на устата и краката."])}
  },
  "Epidemiology": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Епидемиологията е изучаване на факторите, които определят и влияят на честотата и разпределението на заболявания или други здравни събития и техните причини в определена популация с цел контрол на тяхното развитие и разпространение и установяване на програми за предотвратяване на развитието и разпространението."])}
  },
  "Transboundary Animal Diseases": {
    "definition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TADS са заболявания, които са силно заразни или предавани и имат потенциал за много бързо разпространение, независимо от националните граници. Профилирането на заболяването се отнася до клиничните признаци, етиология, патогенеза, клиничен ход, потенциал за предаване и епидемиология на заболяването. Контролът и превенцията са част от компетенцията за контрол и превенция на заболяванията. TAD включват африканска чума по свинете, силно патогенна птича инфлуенца, заразна плевропневмония при говеда, хеморагична септицемия, инфлуенца А, чума по говедата, треска от рифтовата долина, коронавирус на респираторния синдром на Близкия изток и болест на устата и краката."])}
  },
  "userData": {
    "single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един потребител"])},
    "bulk_user_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качване на множество потребители"])},
    "add_a_single_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на един потребител"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
    "select_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете регион"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По избор"])},
    "select_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете позиция"])},
    "csv_upload_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тук е шаблонът за формата на .CSV файла за качване"])},
    "download_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтеглете шаблона"])},
    "cvs_confirmation_positions_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, уверете се, че тези позиции са въведени във вашия .CSV файл точно както се появяват тук. Това са ролите с прикачени компетенции за вашата страна."])},
    "upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качете вашия .CSV файл тук"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпрати"])},
    "adjust_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка на ниво"])},
    "click_to_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кликнете, за да запазите резултатите"])}
  },
  "adjustCompetency": {
    "position_competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетенции на позицията"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позиция"])},
    "competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетентност"])},
    "level_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изисквано ниво"])},
    "view_and_adjust_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тук можете да видите позициите за вашата страна, компетенциите и изискваните нива. Можете също така да настроите изискваното ниво."])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтриране по позиция или компетентност"])}
  },
  "learners": {
    "learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обучаем"])},
    "change_the_position_or_region_in_the_header_above_to_filter_the_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Променете позицията или региона в заглавието по-горе, за да филтрирате таблицата"])},
    "all_learners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички обучаеми"])},
    "only_learners_with_competency_gaps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Само обучаеми с пропуски в компетенциите"])},
    "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имена"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регион"])},
    "competencies_at_required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["компетенции на изисквано ниво %"])},
    "enrol_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записване на обучаеми"])},
    "mark_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отбележи като завършено"])},
    "enrolled_course_completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["завършване на записан курс %"])},
    "completed_assessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завършени курсове"])},
    "no_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма регион"])},
    "list_of_positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Списък на позициите"])},
    "levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нива"])},
    "no_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма ниво"])},
    "awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осведоменост"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начинаещ"])},
    "competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетентен"])},
    "proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитен"])},
    "expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експерт"])},
    "add_user_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на потребителски данни"])},
    "filter_by_position_or_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Филтриране по позиция или компетентност"])},
    "changing_competency_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промяна на нивото на компетентност на"])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за"])},
    "custom_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиран отчет"])},
    "select_course_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете категория на курса"])},
    "download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтеглете отчета"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всички курсове"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моите курсове"])},
    "search_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на вашия курс. Ако вашият курс не се появи в списъка, ще имате възможност да добавите нов курс."])},
    "course_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име на курса"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
    "shortname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кратко име"])},
    "enrolled_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["записани курсове"])},
    "date_enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на записване"])},
    "date_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на завършване"])},
    "add_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на обучаем"])},
    "add_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на дата"])},
    "add_a_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на нов курс"])},
    "choose_learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете обучаем"])},
    "choose_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете курс"])},
    "if_course_not_above_add_new_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако вашият курс не се появи по-горе или в списъка на всички курсове, кликнете върху бутона по-долу, за да добавите нов курс."])},
    "competency_framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рамка на компетентност"])},
    "download_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтеглете този документ"])},
    "click_competency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кликнете върху компетентност, за да видите повече информация за нея,"])},
    "click_competency_framework_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или за да проверите детайлите на рамката на компетентност."])},
    "current_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущо ниво"])},
    "required_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изисквано ниво"])},
    "enrolled_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записани категории"])},
    "add_current_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете вашия текущ регион"])},
    "enter_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете регион тук"])}
  },
  "courseList": {
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на вашия курс. Ако вашият курс не се появи в списъка, ще имате възможност да добавите нов курс."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене на курс..."])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първа"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предишна"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следваща"])},
    "last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последна"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все още не сте добавили никакви курсове."])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница"])}
  },
  "singleLearner": {
    "competencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетенции"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсове"])},
    "self_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самооценка"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напредък"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "year_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Година на завършване"])},
    "no_courses_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нямате курсове в процес."])},
    "no_completed_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нямате завършени курсове."])},
    "get_prepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подгответе се"])},
    "behaviour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поведение"])},
    "pcp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCP"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завършено"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В процес"])}
  },
  "login": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забравена парола?"])}
  },
  "signUp": {
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Създайте акаунт"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако се регистрирате в TOM, ще можете: да запазите резултата от самооценката си, да получите персонализирани предложения за курсове, да управлявате всички сертификати за курсове на едно място, да споделите профила си с ветеринарната власт във вашата страна, която може да се свърже с вас в случай на нужда (в случай че вече имат достъп до TOM)."])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна поща"])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете страна"])},
    "existing_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ако имате съществуващ акаунт на eufmdlearning.works, моля, регистрирайте се, използвайки същия имейл адрес"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрирайте се"])},
    "error_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потребител с този имейл вече съществува."])}
  },
  "selfAssessment": {
    "section_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 1"])},
    "section_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 2"])},
    "section_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 3"])},
    "section_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 4"])},
    "section_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 5"])},
    "section_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 6"])},
    "section_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 7"])},
    "section_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 8"])},
    "section_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 9"])},
    "section_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 10"])},
    "section_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 11"])},
    "section_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 12"])},
    "section_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗДЕЛ 13"])},
    "epi_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с принципите на епидемиологията и тяхното приложение в общите процедури за контрол на болестите (Стандартни оперативни процедури (СОП))."])},
    "epi_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам как да проведа епидемиологично разследване в случай на възникване на болест подлежаща на задължително обявяване."])},
    "epi_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с епидемиологичните подходи, използвани по време на извънредни ситуации и техните въздействия (индивидуални срещу популационни)."])},
    "epi_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С подходящо ръководство мога да помогна в събирането на епидемиологични данни по време на огнище на болест подлежаща на задължително обявяване."])},
    "epi_1_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя епидемиологично разследване екти под съмнение/обекти с потвърдено заболяване обекти, включително определяне на пътищата на предаване на болестта в рамките на обекта."])},
    "epi_1_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да оценя качеството на данните от разследването на болестта."])},
    "epi_1_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да определя източника на заболяването и да се уверя, че е подкрепено от подходящ епидемиологичен анализ."])},
    "epi_1_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да проведа анализ на риска."])},
    "epi_1_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разработя стратегически планове за реакция и да предскажа вероятността за постигане на цели."])},
    "epi_1_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя напреднало планиране за контрол на огнище и фазата на възстановяване."])},
    "tads_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имам знания (видове, клинични признаци и др.) за общи трансгранични болести по животните (Шап, ЗНД, АЧС, Треска от долината Рифт) и инструментите, използвани за тяхната превенция и контрол."])},
    "tads_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам къде да намеря актуална информация за разпространението на болести."])},
    "tads_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с докладваните трансгранични животински болести и описвам патогена, лезиите, предаването, вземането на проби и диагностичните методи, както и всяка необходимост от лични предпазни средства."])},
    "tads_2_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам за националните системи за докладване и знам кои са докладваните трансгранични болести по животните."])},
    "tads_2_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да извършвам клинични прегледи на отделни животни и стада, при които има съмнение за трансгранично заболяване, да определям от кои животни, да определям кои животни да се вземат проби и да обяснявам логиката на диагностичните и терапевтични инструменти."])},
    "tads_2_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога уверено да поставям диагноза при подозрение за трансгранично заболяване да докладвам, както се изисква."])},
    "tads_2_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да предоставя подходящи препоръки, когато клиничните признаци са объркващи."])},
    "tads_2_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм с тежестта на трансграничните животински болести на национално ниво, включително социално-икономическото въздействие."])},
    "disease_prev_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм с принципите на контрол на трансгранични заболявания по животните."])},
    "disease_prev_3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм кои болести по животните изискват задължително докладване и значението на „системата за ранно откриване“, включително как да се получи относителна информация за болестите и как да се реагира на тях."])},
    "disease_prev_3_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да участвам в планове за контрол на болестите (напр. наблюдение, ваксинационни кампании, мониторинг след ваксинация, програми за тестване и унищожаване/лечение)."])},
    "disease_prev_3_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя планове за контрол на болестите, включително координиране с партньори за тяхното ефективно изпълнение."])},
    "disease_prev_3_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да наблюдавам местни публично-частни партньорства (включително ръководене на екип, акредитация, одит)."])},
    "disease_prev_3_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя други в изпълнението на програми в сложни ситуации и да адаптирам процедурите въз основа на нуждите от реакция."])},
    "disease_prev_3_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да развивам публично-частни партньорства на местно ниво."])},
    "disease_prev_3_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да пиша програми за контрол на болестите, базирани на най-добрите практики, и да оценявам тяхната рентабилност."])},
    "disease_prev_3_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да развивам публично-частни партньорства на национално ниво."])},
    "bio_security_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм с мерките за биосигурност в животновъдните обекти и мога да идентифицирам среди, в които е необходима биосигурност."])},
    "bio_security_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм с въздействията при нарушения на биосигурността и относителните мерки за смекчаване."])},
    "bio_security_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да участвам ефективно в огнище на заболяване като член на екип за биосигурност, включително влизане и излизане от обекти по начин съобразен с мерките за биосигурност."])},
    "bio_security_4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разпозная нарушения в биосигурността и да ги коригирам, като приложа подходящите мерки за смекчаване в ситуации с нисък риск."])},
    "bio_security_4_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да прилагам специфични за патогена протоколи за биосигурност в ситуации с висок риск от предаване на болести."])},
    "bio_security_4_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя екип за биосигурност по време на огнище."])},
    "bio_security_4_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да адаптирам процедурите въз основа на нуждите от реакция."])},
    "bio_security_4_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разработвам и одитирам планове за биосигурност за индустрията."])},
    "bio_security_4_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да оценя ветеринарните решения за реакция и да предскажа вероятността за постигане на цели."])},
    "bio_security_4_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разработвам ефективни протоколи за биосигурност."])},
    "sampling_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм с принципите на вземане на проби, включително идентифициране на ситуации, които изискват вземане на проби и последиците от вземането им."])},
    "sampling_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам къде да намеря надеждна информация за планове и техники за вземане на проби."])},
    "sampling_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм с регулациите за вземане на проби, програмите и зоонозния потенциал на болестите по животните."])},
    "sampling_5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да събирам подходящи проби и да ги опаковам (като осигурявам тяхната цялост)."])},
    "sampling_5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да давам съвети за събирането на проби за диагностично тестване, да интерпретирам резултати, да обсъждам фактори, които влияят на резултатите от тестовете, да описвам механизмите на диагностичните тестове, да дефинирам диагностични тестове, тяхното изпълнение и ограничения."])},
    "sampling_5_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да обучавам други в подходящи техники за вземане на проби."])},
    "sampling_5_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разработвам ефективни планове за вземане на проби, като се вземат предвид характеристиките на болестта и ситуацията на вземане на проби."])},
    "animal_id_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с методите, използвани за идентифициране на животни и контрола на животни, животински продукти, оборудване и хора, включително контролни зони."])},
    "animal_id_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с индустриалните модели на движение на животни, продукти и странични продукти."])},
    "animal_id_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога ефективно да контролирам движението на животни извън подозрителни/заразени обекти и да бъда член на екип за контрол по време на огнище."])},
    "animal_id_6_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да прилагам методи за идентификация на животни (напр. ушни марки и татуировки) и да използвам база данни за иентификация, за да проследя историята на движението на животни."])},
    "animal_id_6_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да издавам сертификати за движение на животни в мирно време."])},
    "animal_id_6_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя екип за прилагане на контролни зони по време на огнище и да контролирам движението на животни в и извън контролна зона."])},
    "animal_id_6_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да определя границите на контролна зона, да я модифицирам, ако е необходимо, и да определя дали ситуацията отговаря на условията за уникално изключение."])},
    "animal_id_6_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да обучавам други за контрол на движението."])},
    "animal_id_6_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да проектирам подходящи политики за контрол на движението и да подкрепям тяхното подобрение чрез мониторинг на резултатите от програмата."])},
    "animal_id_6_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога точно да предскажа вероятността за постигане на цели и да предвидя въздействията на ветеринарните решения."])},
    "emergency_disaster_mgt_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм наясно със законодателството, регулиращо реакцията при извънредни ситуации, ролята на ветеринарите в извънредни събития и координацията между правителствени и неправителствени организации, и публични и частни субекти."])},
    "emergency_disaster_mgt_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Под подходящо ръководство мога да участвам в реакция при извънредни ситуации."])},
    "emergency_disaster_mgt_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да приложа концепцията за механизми за координация на инциденти, тяхната функционална способност и ролята на ветеринарите по време на инцидент."])},
    "emergency_disaster_mgt_7_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя други по време на реакции при извънредни ситуации."])},
    "emergency_disaster_mgt_7_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога ефективно да комуникирам по време на кризисни ситуации на местно ниво."])},
    "emergency_disaster_mgt_7_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да координирам сложни инциденти и да идентифицирам различните сектори, които са замесени."])},
    "emergency_disaster_mgt_7_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя по време на сложен инцидент на местно ниво."])},
    "emergency_disaster_mgt_7_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да комуникирам кризи на регионално ниво."])},
    "emergency_disaster_mgt_7_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя национални реакции при извънредни ситуации и комуникация на кризи на национално ниво."])},
    "emergency_disaster_mgt_7_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разпозная политическите чувствителности и да намеря решения."])},
    "emergency_prep_8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наясно съм с ролята на планирането за готовност при извънредни ситуации за приоритетни болести и всякакви относителни планове за готовност."])},
    "emergency_prep_8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам стойността на подхода към готовността от гледна точка на всички опасности."])},
    "emergency_prep_8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да използвам планове за готовност и съм наясно с относителните регулации и Стандартни оперативни процедури (СОП)."])},
    "emergency_prep_8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога уверено да участвам в готовността на местно ниво."])},
    "emergency_prep_8_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разработя специфичен за обекта план за извънредни ситуации (включително събиране на проби) и да го упражня."])},
    "emergency_prep_8_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разпозная подозрителни инвазии на болести и фактори, които влияят на способността за реакция."])},
    "emergency_prep_8_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да подкрепя с проектирането, организирането и провеждането на симулационни упражнения и обучения."])},
    "emergency_prep_8_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да пиша оперативни ръководства и да подкрепям индустрията в разработването на техните планове за непрекъснатост на бизнеса и реакция."])},
    "emergency_prep_8_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да пиша и актуализирам национални планове за контрол на болестите."])},
    "emergency_prep_8_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разработвам инструменти за одит/СОП за проверка на активираните планове."])},
    "emergency_res_9_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с дейностите, които трябва да бъдат включени в реакция на инцидент."])},
    "emergency_res_9_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам къде да намеря ръководни документи/Стандартни оперативни процедури (СОП)."])},
    "emergency_res_9_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да се подготвя за и да помогна в разследване на огнище."])},
    "emergency_res_9_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Под надзор мога да използвам необходимото оборудване и да изпълнявам технически умения по време на огнище (методи за унищожаване, извършване на оценка, почистване и дезинфекция, контрол на дивата природа)."])},
    "emergency_res_9_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя реагиращи по време на огнище."])},
    "emergency_res_9_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да извършвам дейности, свързани с прилагането на закона (запазване на сцената, попечителство на доказателства и др.)."])},
    "emergency_res_9_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да обучавам други в реакция при извънредни ситуации."])},
    "emergency_res_9_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да оценявам резултатите, да оценявам последиците от промените в процедурите и да препоръчвам стратегии за реакция."])},
    "emergency_res_9_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да одобрявам препоръки и да проектирам стратегии за реакция."])},
    "emergency_res_9_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да определям ��одходящи резултати и да предскажа вероятността за постигане на цели за реакция."])},
    "vet_prod_10_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да препоръчвам и прилагам ветеринарни продукти за лечение на общи заболявания по животните."])},
    "vet_prod_10_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да прилагам регулаторни и административни контроли върху антимикробната резистентност (АМР), употребата на антимикробни средства (АМС) и да участвам в тяхното наблюдение."])},
    "vet_prod_10_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да предписвам ветеринарни продукти."])},
    "vet_prod_10_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да намеря и интерпретирам информация относно връзката между употребата на антимикробни средства в селскостопански животни и развитието на АМР в патогени от човешко значение."])},
    "vet_prod_10_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с регулаторните и административни контроли за внос, производство и регистрация на ветеринарни биологични продукти."])},
    "vet_prod_10_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да прилагам наблюдение на АМР."])},
    "vet_prod_10_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имам необходимите умения да одобрявам нови ветеринарни продукти."])},
    "vet_prod_10_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да подкрепя проктирането на програми за наблюдение на АМР."])},
    "vet_prod_10_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да проектирам програми за наблюдение на АМР и политики за регулиране на втеринарни лекарства и биологични продукти."])},
    "vet_prod_10_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разпозная въздействието на извънредни ситуации върху прекъсванията на веригата за доставки и международните ограничения върху движението на биологични продукти."])},
    "animal_welfare_11_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да идентифицирам регулации/стандарти за хуманно отношение към животните и да опиша отговорностите (собственици, ветеринари, манипулатори)."])},
    "animal_welfare_11_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да използвам техники и оборудване за манипулиране на животни по начин, който е съобразен с принципите за хуманното отношение към животните, разпознавайки признаци на болка и стрес."])},
    "animal_welfare_11_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да идентифицирам и докладвам проблеми с хуманното отношение към животните и да участвам в коригиращи действия."])},
    "animal_welfare_11_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да извършвам хуманно евтаназия на отделни животни, включително оценка на зашеметяването/кървенето и прилагането на методи за ограничаване."])},
    "animal_welfare_11_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя екип в масовото умъртвяване и обезвреждане на животни, следвайки протоколи, базирани на методи за хуманно умъртвяване и обезвреждане."])},
    "animal_welfare_11_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да обобщя въздействията върху хуманното отношение на различни методи за контрол (напр. контрол на движението на животни)."])},
    "animal_welfare_11_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя други в изпълнението на програми за хуманно отношение към животните в сложни ситуации и да адаптирам процедурите въз основа на нуждите от реакция."])},
    "animal_welfare_11_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да избера подходящи методи за хуманно умъртвяване и обезвреждане на голям брой животни въз основа на конкретната ситуация."])},
    "animal_welfare_11_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да пиша програми за съответствие, използвайки научна литература, политика и насоки."])},
    "animal_welfare_11_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога точно да предскажа социалното приемане на действията за реакция и да предвидя въздействията на ветеринарните решения."])},
    "risk_12_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам з концепциите за анализ на риска: идентификация на опасности, оценка на риска, бърза оценка на риска, управление на риска, комуникация на риска."])},
    "risk_12_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам как анализът на риска може да се използва, за да се гарантира, че животинското, човешкото и екологичното здраве са защитени в ситуации на бедствия."])},
    "risk_12_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да намеря информация, необходима за извършване на анализ на риска."])},
    "risk_12_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знам как анализът на риска може да се приложи към оценката на риска от планиране/смекчаване/подготовка за извънредни ситуации с животински болсти и възстановяване, остатъци от ветеринарни лекарства, включително внос на животни и животински продукти."])},
    "risk_12_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да използвам основни/ключови концепции и принципи на анализа на риска в съответствие с предписаната политика и процедури/насоки."])},
    "risk_12_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да картографирам местни вериги на стойността и да предавам рисковете от предаване на болести."])},
    "risk_12_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да прилагам принципите на анализа на риска в необичайни/сложни ситуации и да подкрепям вземането на решения по време на сложни ситуации."])},
    "risk_12_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да идентифицирам и управлявам планове за комуникация, свързани с рисковете от предаване на болести."])},
    "risk_12_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да извършвам анализ на риска на ниво организация."])},
    "risk_12_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да подкрепям решения за управление на риска и стратегии за комуникация на риска."])},
    "safety_13_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с опасностите на работното място и принципите на безопасността на животните, хората и околната среда в извънредни ситуации."])},
    "safety_13_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аз съм наясно с последиците от управлението на извънредни ситуации и бедствия върху поминъка."])},
    "safety_13_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да идентифицирам източници на подкрепа (лична и организационна)."])},
    "safety_13_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разпозная работни среди, които НЕ отговарят на подходящите стандарти за здраве и безопасност."])},
    "safety_13_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да разпозная признаци на влошаване на благосъстоянието в другите (първа помощ за психично здраве) и да се намеся."])},
    "safety_13_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да предприема коригиращи действия за отстраняване на условията на работа, водещи до опасна работна среда."])},
    "safety_13_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да ръководя разследвания на инциденти или близки пропуски."])},
    "safety_13_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да правя препоръки за намаляване на вероятността от психически или физически здравни опасности."])},
    "safety_13_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мога да пиша/актуализирам политики и процедури за защита на безопасността, здравето и благосъстоянието на служителите."])},
    "rank_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, оценете нивото, на което сте съгласни със следните твърдения."])},
    "survey_sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздели на анкета"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добре дошли в самооценката на TOM."])},
    "welcome_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази оценка ви позволява автоматично да оцените Вашето ниво спрямо заложените в системата компетенции, свързани с превенцията, реакцията и контрола на <bold>Шап и подобни трансгранични болести по животните</bold> болести."])},
    "welcome_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вляво ще видите списък с компетенции, налични за оценка въз основа на избраната от вас страна."])},
    "welcome_list_item_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Започнете, като изберете областта (компетенция), в която искате да бъдете оценени. След това отговорете на съответната серия от въпроси."])},
    "welcome_list_item_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. TOM ще ви предостави обратна връзка за очакваното ниво на компетентност."])},
    "welcome_list_item_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Можете да запазите вашите резултати, като създадете нов профил в TOM. Този профил ще ви позволи <bold>достъп до препоръки за курсове въз основа на вшите резултати</bold> и да споделите вашия профил с ветеринарния орган във вашата страна, който може да се свърже с вас, ако е необходимо."])},
    "guest_welcome_description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да започнете вашата оценка, моля, изберете вашата страна, за да адаптирате наличните компетенции към вашия специфичен регион."])},
    "guest_welcome_description_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Самооценката на TOM в момента се пилотира и приветстваме вашите отзиви на ", _interpolate(_named("email")), "."])},
    "click_survey_section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За да започнете, кликнете върху някой от разделите на анкетата в страничния панел."])},
    "thank_you_for_completing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим ви, че завършихте самооценката на TOM!"])},
    "assessment_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпрати"])},
    "section_scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценки на разделите"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Силно съгласен"])},
    "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не съм съгласен"])},
    "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не е приложимо"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въпросник за самооценка - Рамка за компетентност за контрол на болестите"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниво"])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете страна, за да започнете оценката:"])},
    "select_default_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете страна"])},
    "submit_button_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпрати"])}
  },
  "routes": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добре дошли"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
    "loginRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход / Регистрация"])},
    "PasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нулиране на парола"])},
    "Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсове"])},
    "Nominee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кандидат"])},
    "Request Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка за курс"])},
    "Training Credit System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система за кредитиране на обучени"])},
    "Suggestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложения"])},
    "Future Features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бъдещи функции"])},
    "Single Learner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Един обучаем"])},
    "Competency Framework": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рамка на компетентност"])},
    "Custom Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонализиран отчет"])},
    "Add User Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавяне на потребителски данни"])},
    "Adjust Levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка на нива"])},
    "admin_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администраторски вход"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
    "Learner Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профил на обучаем"])}
  },
  "positions": {
    "central_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Централен ветеринар"])},
    "regional_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регионален ветеринар"])},
    "senior_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старши полеви ветеринар"])},
    "expert_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ветеринарен експерт"])},
    "paraprofessional_vet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ветеринарен парапрофесионалист"])},
    "lab_staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лабораторен персонал"])}
  },
  "regions": {
    "armenia": {
      
    }
  },
  "competencies": {
    "epidemiology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Епидемиология"])},
    "tads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трансгранични болести по животните"])},
    "disease_prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Програми за превенция и контрол на болестите"])},
    "biosecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биосигурност"])},
    "sampling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вземане на проби"])},
    "animal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификация и контрол на движението на животни"])},
    "emergency_disaster_mgt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на извънредни ситуации и бедствия"])},
    "emergency_prep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовност за извънредни ситуации"])},
    "emergency_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реакция при извънредни ситуации"])},
    "vet_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ветеринарни продукти"])},
    "animal_welfare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хуманно отношение към животните"])},
    "risk_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение на анализ на риска"])},
    "safety_health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасност, здраве и благосъстояние"])},
    "animal identification and movement control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификация и контрол на движението на животни"])},
    "Emergency and Disaster Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление на извънредни ситуации и бедствия"])},
    "Emergency Preparedness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готовност за извънредни ситуации"])},
    "Emergency Response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реакция при извънредни ситуации"])},
    "Veterinary Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ветеринарни продукти"])},
    "Animal Welfare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хуманно отношение към животните"])},
    "Application of Risk Analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложение на анализ на риска"])},
    "Safety, Health and Wellbeing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасност, здраве и благосъстояние"])}
  },
  "compLevels": {
    "Awareness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осведоменост"])},
    "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начинаещ"])},
    "Competent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компетентен"])},
    "Proficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитен"])},
    "Expert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Експерт"])}
  },
  "certificates": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Име"])},
    "date_uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на качване"])},
    "no_certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Няма сертификати за показване"])},
    "drag_n_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плъзнете и пуснете PDF файлове тук или кликнете, за да качите"])},
    "file_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимален размер на файла: 2 MB"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предишен"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следващ"])},
    "certs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификати"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качване на нов сертификат"])}
  },
  "externalCourses": {
    "external_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Външни курсове"])},
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лице за контакт"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Връзка"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейл"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
  },
  "supportLanguages": {
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
    "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Castellano"])},
    "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
    "serbian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сербский"])},
    "bulgarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["болгарский"])}
  }
}