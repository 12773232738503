const supportedLocales = {
  en: "English",
  it: "Italian",
  rus: "Russian",
  es: "Spanish",
  srb: "Serbian",
  bg: "Bulgarian",
};

export const getSupportedLocales = () => {
  let annotatedLocales = [];

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code],
    });
  }
  return annotatedLocales;
};

export const supportedLocalesInclude = locale => {
  return Object.keys(supportedLocales).includes(locale);
};
