// detect user's preferred locale from browser
const getBrowserLocale = (options = {}) => {
  const defaultOptions = { languageCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  // check browser's navigator.languages array
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  // navigator.language: "en"
  // navigator.language: "en-US". No need for regional marker so trim it. split the string at either a - or _

  const trimmedLocale = opt.languageCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();

  // console.log({ navigatorLocale }, navigator, { trimmedLocale });
  return trimmedLocale;
};
export default getBrowserLocale;
