export default {
  Epidemiology: {
    core: "Приложение на епидемиологични принципи (OIE 2.1) (включва 3.3)",
    definition:
      "Епидемиологията е изследване на факторите, определящи и влияещи върху честотата и разпространението на болести или други здравни събития и техните причини в определена популация с цел контролиране на тяхното развитие и разпространение и създаване на програми за предотвратяване на развитие и разпространение.",
    importance:
      "Служи като основа и логика на интервенциите, направени в интерес на спешната реакция за здравето на животните, ветеринарното обществено здраве и превантивната медицина.",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Опитен",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да обясните общите принципи на описателната епидемиология, нейното приложение към болестите и контрола на болестите, достъп и използване на подходящи източници на информация (SOPs), обяснете как да проведете епидемиологично разследване в случай на възникване на докладваема болест",
        role: "Наемане на ден 1 във VS",
        getPrepared: "Основи",
        PCP: "",
        PVS: "",
        EuFMDCourses: "",
        ISAVET: "Полево разследване/Реакция 7.1",
        BTSF: "",
        USAID: "",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да прилагате знания за клиничните и епидемиологичните характеристики на инфекциозните болести, да подпомагате епидемиологично разследване (събиране на епидемиологични данни на подготвена форма) в случай на възникване на докладваема болест, да опишете епидемиологичните подходи, необходими в случаи на животински извънредни ситуации и въздействията на избраните епидемиологични подходи (индивидуални срещу популационни)",
        role: "Частен практикуващ, нает във VS Ветеринарен парапрофесионалист",
        getPrepared: "Аларма",
        PCP: "1,2,3",
        PVS: "",
        EuFMDCourses: "FEPC FITC RRT ASF LSD",
        ISAVET:
          "Епидемиологично наблюдение 2.1, 2.2 Полево разследване/Реакция 7.1-7.4",
        BTSF: "",
        USAID: "Първа линия 2.8b",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да ръководите епидемиологично разследване на подозрителни/потвърдени обекти (включва проследяване навън и навътре, прилагане на дефиниция на случай), определяне на пътищата на предаване на болестта на заразени обекти, включва използване на полеви епидемиологични знания за събиране на данни, оценка на данни от разследване на болестта (качество и пълнота).",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Аларма Извънредна ситуация",
        PCP: "1,2,3",
        PVS: "II-2 3",
        EuFMDCourses: "FEPC FITC RRT ASF LSD",
        ISAVET: "Полево разследване/Реакция 7.4-7.5",
        BTSF: "",
        USAID: "Първа линия 1.3 Първа линия 2.8d-h",
      },
      {
        name: "Опитен",
        behaviour:
          "Можете да прилагате напреднали принципи на епидемиологията за определяне на източника на огнище, валидиране и анализ на полеви данни, използване на епидемиологични модели, провеждане на анализ на риска, правене на препоръки за действия за изпълнение, предвиждане на въздействия (ползи, отговорности, въздействия върху заинтересованите страни), разработване на епидемиологични въпросници, проектиране на инструменти за събиране на данни и вземане на решения въз основа на събраните данни (напр. стратегия за наблюдение)",
        role: "Ветеринарен съветник/епидемиолог",
        getPrepared: "Аларма Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses:
          "RAVC Социо-икономически PVM VVPV Моделиране и други епидемиологични работилници",
        ISAVET: "Епидемиологично наблюдение 3.1-3.3 4.3-4.4 5.1-5.2",
        BTSF: "Подготовка за животински болести/ранно предупреждение/план за извънредни ситуации/контрол на болестите",
        USAID:
          "Първа линия 1.4c-f Първа линия 1.5b Междинно 1.2 Междинно 2.8av,2.8bi-iii, vi 2.8ci-vi",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да ръководите разработването на стратегически планове за реакция, включително животински, човешки и екологични сектори (едно здраве), предвиждате вероятността за постигане на цели, предвиждате въздействия (ползи, отговорности, заинтересовани страни), отговорни за напреднало планиране по време на огнище, стратегия за възстановяване",
        role: "Разработчик на политики",
        getPrepared: "Основи",
        PCP: "",
        PVS: "II-2 4, 5",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "",
        USAID: "Първа линия 3.11b Междинно 3.12a",
      },
    ],
    competency: "Епидемиология",
  },
  "Transboundary Animal Diseases": {
    core: "Трансгранични животински болести (TADs) (OIE 2.3) Профилиране на болести",
    definition:
      "TADs са болести, които са силно заразни или преносими и имат потенциал за много бързо разпространение, независимо от националните граници. Профилирането на болести се отнася до клиничните признаци, етиологията, патогенезата, клиничния ход, потенциала за предаване, епидемиологията на болестта. Контролът и превенцията са в компетенцията на контрола и превенцията на болестите. TADs включват африканска чума по свинете, силно патогенен птичи грип, заразна плевропневмония по говедата, хеморагична септицемия, грип A, чума по говедата, треска на Рифт Вали, коронавирус на близкоизточния респираторен синдром и шап.",
    importance:
      "Агентите на TADs може да са или да не са зоонозни, но независимо от зоонозния потенциал, силно заразният характер на тези болести представлява постоянна заплаха за препитанието на животновъдите. Тяхното възникване може също да има значителен вреден ефект върху националните икономики и може да повлияе на глобалната икономика, глобалната търговия и глобалното обществено здраве.",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да изброите и опишете общите TADs (засегнати видове, клинични признаци, диференциална диагноза, клиничен ход, основни пътища на предаване, потенциални пътища на влизане в страната), да изброите терапевтичните инструменти, използвани за превенция и борба (включително ваксинация), да намерите актуална информация за разпространение;",
        role: "Няма",
        getPrepared: "Основи",
        PCP: 0,
        PVS: "II-2A 3 II-2B 3",
        EuFMDCourses: "FEPC,FTIC,RRT,ASF,LSD",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ранно предупреждение/план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да изброите уведомителните TADs, да идентифицирате националната система за докладване, - да опишете патогена, лезиите, патофизиологията, потенциала за предаване (включително вектори) и вземане на проби, - да опишете необходимостта от ЛПС за здраве и безопасност, - да изброите диагностичните инструменти, използвани за превенция и борба, да можете да прегледате живи животни",
        role: "Частен практикуващ, нает във VS, Ден 1 нает във VS, Ветеринарен парапрофесионалист",
        getPrepared: "Предупреждение",
        PCP: "1,2,3",
        PVS: "II-2A 3 II-2B 3",
        EuFMDCourses: "FEPC FITC RRT ASF LSD",
        ISAVET: "Епидемиологично наблюдение 2.1, 2.3",
        BTSF: "",
        USAID: "",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да извършите клиничен преглед на стадо и индивидуално животно със съмнение за TAD (включва аутопсия и специфични за вида характеристики), да определите възрастта на лезиите (ако е приложимо), да определите животните за проби, да можете да обясните логиката на диагностичните и терапевтичните инструменти, да можете да определите вероятността, че болестта е TAD (надеждно диагностициране на съмнение), да докладвате, както е необходимо",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Предупреждение",
        PCP: "",
        PVS: "II-2A 4",
        EuFMDCourses: "FEPC FITC VVPV PVM? RRT ASF LSD",
        ISAVET: "Полево обучение",
        BTSF: "",
        USAID: "Първа линия 1.3 Първа линия 2.8a Първа линия 2.8b",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да предоставите насоки и обучение на по-ниски нива, да адаптирате диагностичните и терапевтичните инструменти въз основа на новата наука, да правите препоръки/решения, когато клиничните признаци са объркващи или неясни, да можете да се свържете с мрежи извън домашната организация, – да можете да предоставите най-актуалната информация за профила на болестта, включително резултатите от последните изследвания, да опишете теж��стта на TADs на национално ниво (заболеваемост, смъртност, аборти, намалено производство и т.н.), - да опишете социално-икономическото въздействие на TAD на национално ниво",
        role: "Разработчик на политики, Ветеринарен съветник",
        getPrepared: "Основи",
        PCP: "",
        PVS: "II-2A 4",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "",
        USAID: "",
      },
    ],
    competency: "Трансгранични животински болести",
  },
  "Disease Prevention and Control Programs General": {
    definition:
      "Програмите за превенция и контрол на болестите включват наблюдение, контрол на движението, ваксинация, лечение, зони на ограничение, биосигурност, изолация, унищожаване и изкореняване. Те могат да бъдат съвместни програми (публично-частни партньорства). Някои контролни програми са резултат от прилагането на други компетенции, например програма за наблюдение е резултат от вземане на проби, клинични признаци и епидемиология. Други са самостоятелни компетенции.",
    importance:
      "Програмите за превенция и контрол са ключови за управлението на TADs, възникващи и повторно възникващи болести, защита на благосъстоянието на животните, търговията, индустрията и жизнеспособността на отделните производители, продоволствената сигурност.",
    competency: "Общи програми за превенция и контрол на болестите",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да обясните принципите на контрол на болестите (роля/характеристика на наблюдението), да опишете установените програми и тяхната роля в тях за превенция и контрол на TADs, да обясните концепцията за „система за ранно откриване“, да изброите кои болести по животните изискват задължително уведомление, да намерите актуална и надеждна информация относно специфични болести, мерки за превенция и контрол, включително механизми за бързо реагиране, да опишете подхода „Едно здраве“.",
        role: "Ден 1 нает във VS",
        getPrepared: "Основи",
        PCP: "3",
        PVS: "I-5 3",
        EuFMDCourses: "PPP",
        ISAVET: "Полево разследване/ Реакция 7.1",
        BTSF: "Епидемиологично наблюдение 1.1-1.3, Превенция/ Контрол на болестите 13.1-13.2",
        USAID:
          "Първа линия 1.1, Първа линия 1.4a, Първа линия 2.10b-c, Средно ниво 1.1a",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да участвате в установени планове за контрол на болестите като наблюдение, ваксинационни кампании, мониторинг след ваксинация, програми за тестване и унищожаване/лечение.",
        role: "Частен практикуващ, нает във VS, Ветеринарен парапрофесионалист",
        getPrepared: "Предупреждение за извънредни ситуации",
        PCP: "3",
        PVS: "I-5 3",
        EuFMDCourses:
          "FEPC FITC PPP VVPV PVM RRT Наблюдение на дивата природа ASF LSD",
        ISAVET: "Епидемиологично наблюдение 4.1",
        BTSF: "",
        USAID: "Първа линия 2.10a",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да ръководите (на място или виртуално) изпълнението на планове за контрол на болестите, да обобщавате и докладвате данни от наблюдение, да действате, ако инцидентите надвишават праговете, да наблюдавате местни публично-частни партньорства (което може да включва ръководене на екип, акредитация и/или одит), да обяснявате политики и резултати на засегнатите страни, да координирате с други страни и заинтересовани страни за ефективно изпълнение на програмата, да препоръчвате основни мерки за превенция и контрол на огнища.",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Предупреждение за извънредни ситуации",
        PCP: "3",
        PVS: "I-5 3",
        EuFMDCourses:
          "FEPC FITC PPP VVPV PVM RRT Наблюдение на дивата природа ASF LSD",
        ISAVET:
          "Епидемиологично наблюдение 2.0, 4.2, 4.5, Полево разследване /Реакция 7.6",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "Първа линия 1.4b Първа линия 1.5a, Първа линия 2.8.i",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да предоставите насоки на полевия персонал относно изпълнението и интерпретацията на програмите в необичайни/сложни ситуации, - да опишете защо политиката е проектирана по този начин, да предвидите въздействието на промените в изпълнението на програмата и да направите препоръки, - да подобрите програмите въз основа на опит и да приложите научените уроци по-широко сред заинтересованите страни, да развиете публично-частни партньорства на местно ниво.",
        role: "Ветеринарен съветник",
        getPrepared: "Предупреждение за извънредни ситуации",
        PCP: 3,
        PVS: "",
        EuFMDCourses: "VVPV Оценка на риска PVM",
        ISAVET: "Епидемиологично наблюдение 5.3",
        BTSF: "",
        USAID: "Средно ниво 1.1b-d",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да пишете/модифицирате програми въз основа на епидемиология, анализ на риска, мониторинг на резултатите, глобални стратегии за контрол на болест, включително животински, човешки и екологични сектори (едно здраве), да оцените ефективността на програмата, да получите достъп до най-новата наука, да получите достъп и да приложите най-добрите практики/препоръки на управляващия орган (например OIE), да предвидите вероятността програмата да постигне целите си, да развиете публично-частни партньорства на национално ниво.",
        role: "Разработчик на политики",
        getPrepared: "Основи, Възстановяване",
        PCP: "3",
        PVS: "I-5, 4,5",
        EuFMDCourses: "VVPV PVM Оценка на риска",
        ISAVET: "",
        BTSF: "",
        USAID: "Първа линия 3.11b Средно ниво 2.8di, Средно ниво 3.12a",
      },
    ],
  },
  Biosecurity: {
    definition:
      "Комплект от управленски и физически мерки, предназначени да намалят риска от въвеждане, установяване и разпространение на животински болести, инфекции или зарази към, от и в рамките на животинска популация. Включва концепциите за биоизключване, биозадържане и биоуправление, във връзка с фермата и личната биосигурност. Отнася се до способността за движение и работа между обекти, без да се превръщате в вектор за болест, по време на ежедневна работа и ситуации на огнища.",
    importance:
      "Производителите поставят значително доверие в VS, особено по време на ситуации на огнища. Биосигурността е от съществено значение за защитата на незаразени ферми и за осигуряване на разрешено движение.",
    competency: "Биосигурност",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да дефинирате биосигурност, да обясните практиките за биосигурност във фермата, да разпознаете принципите на биосигурност, да разпознаете среди, нуждаещи се от биосигурност, да опишете въздействията от нарушение на биосигурността, мерки за смекчаване.",
        role: "Няма",
        getPrepared: "Основи",
        PCP: "",
        PVS: "II-2 A 3 II-2 B 3",
        EuFMDCourses: "FEPC/FIT, C, RRT, Asf",
        ISAVET: "",
        BTSF: "",
        USAID: "",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да прилагате принципите на биосигурност (къде да паркирате и поддържане на чиста зона в превозното средство), оборудване (включва грижа и поддръжка) и процеси, необходими за безопасно движение между обекти по време на редовен/нисък пренос на болести, да разпознаете нарушение на биосигурността, - да оцените риска и да въведете мерки за смекчаване в ситуации с нисък риск, да обясните как да влезете и напуснете обект, заподозрян в FAST болест, безопасно да облечете и свалите ЛПС, член на екип за биосигурност по време на огнище.",
        role: "Частен практикуващ, нает във VS, Ветеринарен парапрофесионалист",
        getPrepared: "Предупреждение",
        PCP: "1,2,3 ветеринари, 2,3 ПП",
        PVS: "II-2 A 3 II-2 B 3",
        EuFMDCourses: "FEPC RRT ASF",
        ISAVET: "Полево разследване/Реакция 7.1",
        BTSF: "",
        USAID: "Първа линия 2.7 Средно ниво 3.12b",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да прилагате специфични за патогена биосигурностни протоколи при съмнение за TAD или FAST болест (висок риск от пренос на болести): създаване на зони за биозадържане, безопасно премахване на проби от заразени обекти, ЛПС, оценка на риска и въвеждане на мерки за смекчаване, в случаи на нарушение на биосигурността в ситуации с висок риск от пренос на болести, ръководене на екип за биосигурност по време на огнище, използване на участнически подходи при обяснение на препоръките за биосигурност на производителите.",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Предупреждение",
        PCP: "",
        PVS: "II-2 4",
        EuFMDCourses: "RRT",
        ISAVET: "Полево разследване /Реакция 7.1",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "Първа линия 2.7 Средно ниво 3.12b",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да проектирате и администрирате обучение по биосигурност, да адаптирате процедури въз основа на нуждите от реакция (изисква се преценка и оценка на риска), да оценявате нововъзникващи технологии и да правите препоръки въз основа на надеждна ветеринарна информация, разработване и одитиране на индустриални планове за биосигурност.",
        role: "Ветеринарен съветник",
        getPrepared: "Основи",
        PCP: "",
        PVS: "II-2 A 4",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "",
        USAID: "Средно ниво 3.12b",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да разработвате политики за биосигурност, да предвиждате вероятността за постигане на цели, да одобрявате ветеринарни решения за реакция, да предвиждате въздействията на ветеринарните решения (напр. ползи, отговорност, национални, заинтересовани страни).",
        role: "Разработчик на политики",
        getPrepared: "Основи",
        PCP: "",
        PVS: "II-2 4",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "",
        USAID: "Средно ниво 3.12b",
      },
    ],
  },
  Sampling: {
    definition:
      "Събиране на проби, провеждане на регулаторни диагностични тестове и интерпретиране на резултатите",
    importance:
      "Резултатите от вземането на проби се използват за вземане на критични решения относно обявяването на наличие на болест или възстановяване на свободен статус. Доверието в резултатите се основава на качеството на събраните проби и поддържането на качеството по време на транспортиране.",
    competency: "Вземане на проби",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "описвате целта на вземането на проби, идентифицирате ситуациите, които изискват тестване, разпознавате последиците от вземането на проби, намирате актуална и надеждна информация относно специфични планове/техники за вземане на проби",
        role: "Няма",
        getPrepared: "Основи",
        PCP: "",
        PVS: "",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "",
        USAID: "",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да очертаете регулаторните задължения за вземане на проби, да разпознаете програмата, под която попада вземането/тестването, да разпознаете зоонозния потенциал на проба, да докладвате резултатите на OV, да събирате проби за диагностични или тестови цели (антемортем и постмортем), да опаковате за изпращане, да осигурите целостта на пробите. (включва безопасно събиране, обработка и познания за транспортиране на опасни стоки)",
        role: "Частен практикуващ, нает във VS, Ден 1 нает във VS, Ветеринарен парапрофесионалист",
        getPrepared: "Предупреждение",
        PCP: "2,3",
        PVS: "II-4AB 2,3",
        EuFMDCourses: "FEPC FITC RRT Наблюдение на дивата природа ASF LSD",
        ISAVET: "Полево разследване/Реакция 6.2",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "Първа линия 2.9b-c Средно ниво 2.8biv",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да предоставите съвети относно събирането на проби за диагностични или тестови цели (антемортем и постмортем), да интерпретирате и обяснявате резултатите (на място и в лаборатория), да обсъждате факторите, влияещи на резултатите от тестовете, да опишете механизма на диагностичните тестове, да дефинирате терминологията за изпълнение на диагностични тестове, да обясните ограниченията на всеки тест, да обучавате индустрията или други трети страни за вземане на проби, да координирате планове за самостоятелно вземане на проби.",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Предупреждение",
        PCP: "2,3",
        PVS: "II-4AB 2,3",
        EuFMDCourses: "FEPC FITC RRT Наблюдение на дивата природа ASF LSD",
        ISAVET: "",
        BTSF: "",
        USAID: "Средно ниво 2.8aiv",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да разработвате планове за вземане на проби, като се вземат предвид характеристиките на болестта и ситуацията на вземане на проби (индивидуално срещу стадо, наблюдение срещу подозрение, това включва преглед на научната литература, международни стандарти и нови методологии)",
        role: "Разработчик на политики, Ветеринарен съветник",
        getPrepared: "Основи",
        PCP: "",
        PVS: "",
        EuFMDCourses: "FLITC",
        ISAVET: "",
        BTSF: "",
        USAID: "",
      },
    ],
  },
  "ID and Movement Control": {
    definition:
      "Комбинацията от идентификация и регистрация на животно индивидуално, с уникален идентификатор, или колективно по неговата епидемиологична единица или група, с уникален групов идентификатор. Включва способността да се регулира идентификацията на животни и да се проследяват и наблюдават вътрешните движения по време на всички етапи от живота и свързването на компоненти като идентификация на обекти или собственици, лицата, отговорни за животните, движенията и други записи с идентификацията на животните. Това включва съвместно управлявани програми (публично-частни партньорства) в нормален статус на болест и извънредни ситуации (контролни зони и движение от обекти).",
    importance:
      "Способността да се идентифицират животни (индивидуално, по партида, по обект) е основата за много дейности по контрол на болестите като управление на подозрения и потвърждения на огнища, ваксинация и проследяване на движенията. Без средство за идентификация повече животни/обекти трябва да се считат за потенциално замесени в огнище, което забавя времето за реакция и увеличава натоварването на ресурсите.",
    competency: "Контрол на идентификацията и движението",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да опишете методи за идентификация на животни, да дефинирате контролна зона, да опишете целта на контролна зона, да опишете методи, използвани за контрол на движението на животни, животински продукти, оборудване и хора, да опишете индустриалните модели на движение за животни, продукти и странични продукти.",
        role: "Ден 1 нает във VS",
        getPrepared: "Основи",
        PCP: "3",
        PVS: "II-12A 3 II-12B 2",
        EuFMDCourses: "FEPC/FIT, RTT, ASF, LSD +, други работилници",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да опишете установени програми за идентификация на животни, да опишете принципите на контролни зони, да приложите метод за идентификация (ушна марка, чип и т.н.), да контролирате движението на животни от подозрителни/заразени обекти (включва документация на регулаторния орган), член на екип за контрол на движението в зона по време на огнище, да използвате база данни за идентификация (национална и/или ЕС) за проследяване на историята на движение на животни и т.н.",
        role: "Частен практикуващ, нает във VS, Ветеринарен парапрофесионалист",
        getPrepared: "Извънредна ситуация",
        PCP: "3",
        PVS: "II-12 A 3 II-12 B 2",
        EuFMDCourses: "FEPC/FIT, RTT, ASF, LSD +, други работилници",
        BTSF: "",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да обучавате производители относно ползите и изискванията на програмата за идентификация на животни, сертифициране на движение на животни в мирно време (национално или на ниво ЕС), да наблюдавате местни публично-частни партньорства (водене на екип, акредитация и/или одит) в подкрепа на програмата за идентификация на животни, да контролирате движението на животни в/вътре и извън контролна зона (включва документация на регулаторния орган), да водите екип за прилагане на контролни зони по време на огнище, да определите дали ситуации отговарят на известни изключения.",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Основи Извънредна ситуация",
        PCP: "3",
        PVS: "II-12 A 3 II-12 B 2",
        EuFMDCourses: "FEPC/FIT, RTT, ASF, LSD +, други работилници",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да определите границите на контролна зона, да модифицирате контролни зони според нуждите, да разпознаете факторите, които влияят на решенията за контрол на движението, да разпределите ресурси за прилагане, да проектирате и предоставите обучение за контрол на движението, да определите дали ситуации отговарят на уникални или необичайни изключения.",
        role: "Ветеринарен съветник",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses: "",
        BTSF: "",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да пишете и модифицирате политики и програми за контрол на движението въз основа на епидемиология, анализ/планиране на риска, да наблюдавате резултатите, за да подкрепите непрекъснатото подобряване на политиките и програмите с течение на времето, да работите с партньори за идентифициране на ресурси за прилагане, да предвидите вероятността за постигане на цели, да предвидите въздействията на ветеринарните решения (напр. ползи, отговорност, национални, заинтересовани страни).",
        role: "Разработчик на политики",
        getPrepared: "Основи",
        PCP: "",
        PVS: "II-12 4,5 II-12 B 2",
        EuFMDCourses: "",
        BTSF: "",
      },
    ],
  },
  "Emergency and Disaster Management": {
    definition:
      "Отнася се до организационната структура и процесите/практиките за координация на извънредна ситуация, включително дейности като реакция, контролни програми и комуникация.",
    competency: "Управление на извънредни ситуации и бедствия",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да опишете фазите на извънредно събитие и ветеринарните роли в подготовката, реакцията, възстановяването и превенцията или смекчаването, да идентифицирате законодателството, регулиращо реакцията при извънредни ситуации, да обясните интеграцията и координацията между правителствени и неправителствени реагиращи, власти и публични и частни субекти, да обясните концепцията за механизми за координация на инциденти, тяхната функционална способност и ролята на ветеринарите, включително в непреки аспекти като непрекъснатост на бизнеса, продоволствена и фуражна сигурност и благосъстояние на животните, да участвате в реакция при извънредна ситуация под ръководство.",
        role: "Частен практикуващ, нает във VS, Ден 1 нает във VS, Ветеринарен парапрофесионалист",
        getPrepared: "Основи/ Извънредна ситуация",
        PCP: "2,3",
        PVS: "I-6 A 3",
        EuFMDCourses: "Управление на криза",
        ISAVET: "Подготовка 11.1",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да приложите концепцията за механизми за координация на инциденти, тяхната функционална способност и ролята на ветеринарите, по време на едно събитие или начален инцидент, да ръководите работата на други в VS и изпълнители на полево ниво по време на реакцията.",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Извънредна ситуация",
        PCP: "2,3",
        PVS: "I-6 A 3",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да приложите концепцията за механизми за координация на инциденти, тяхната функционална способност и ролята на ветеринарите, в разширяващ се/сложен инцидент, да заемете специфична функционална позиция (лидер на екип) в рамките на местната структура за извънредни ситуации, да ръководите действията на персонал извън VS (изпълнители, външни заинтересовани страни) на местно ниво, кризисна комуникация на местно ниво, да идентифицирате секторите, които могат да бъдат ангажирани в решаването на многостранно здравно събитие.",
        role: "Старши напълно функционален държавен полеви ветеринар (по-опитен)",
        getPrepared: "Извънредна ситуация",
        PCP: "2,3",
        PVS: "I-6 A 3",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "Първа линия 3.11b",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да приложите концепцията за механизми за координация на инциденти, включително координация на много агенции и непреки аспекти като непрекъснатост на бизнеса, продоволствена и фуражна сигурност и благосъстояние на животните по време на сложен и разширяващ се инцидент, да заемете специфична функционална позиция, напр. епи лидер в рамките на структурата за извънредни ситуации на ниво регион на страната, да ръководите действията на други на местно ниво, да разпознаете политическите чувствителности, кризисна комуникация на ниво регион на страната.",
        role: "Ветеринарен съветник",
        getPrepared: "Извънредна ситуация",
        PCP: "2,3",
        PVS: "I-6 A 3",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да приложите концепцията за механизми за координация на инциденти, включително международна координация и комуникация по време на национални инциденти, да заемете специфична функционална позиция в рамките на структурата за извънредни ситуации на национално ниво, да ръководите действията на други на национално ниво, да разпознаете политическите чувствителности и да намерите решения, кризисна комуникация на национално ниво.",
        role: "Разработчик на политики",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "I-6 A 4-5",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
    ],
  },
  "Emergency Preparedness": {
    definition:
      "Способността да бъдете подготвени за бърза реакция на огнище на болест или извънредна ситуация в областта на безопасността на храните. Включва ключови дейности около планирането и упражненията.",
    competency: "Подготовка за извънредни ситуации",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да опишете ролята на планирането за подготовка за извънредни ситуации (включително обучение и упражнения) в контрола на приоритетни болести, да намерите планове за болести, обекти или други планове за подготовка (планове, специфични за обекти, превенция на болести, контрол и бърза реакция), да опишете стойността на подхода към подготовката от гледна точка на всички опасности.",
        role: "Частен практикуващ, нает във VS, Ден 1 нает във VS",
        getPrepared: "Няма",
        PCP: "2,3",
        PVS: "II-5 3",
        EuFMDCourses: "FEPC FIPC ASF LSD",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да използвате планове за подготовка, да участвате в подготовка на местно ниво (списъци с контакти, участие в обучение), да разпознавате общи тригери за разследване (местна ситуационна осведоменост), да обясните регулации и SOPs, свързани с позицията.",
        role: "Няма",
        getPrepared: "Няма",
        PCP: "",
        PVS: "",
        EuFMDCourses: "",
        BTSF: "",
        USAID: "Първа линия 2.6A",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да разработите специфичен за обекта план за извънредни ситуации (кланица, пазар и т.н.), да разработите логистичен план за събиране на проби, да упражнявате специфични за обекта планове за извънредни ситуации, да разпознавате факторите, които влияят на способността за реакция, да разпознавате подозрителни биологични събития (включително клъстери, дефиниция на случаи и тригери).",
        role: "Полеви ветеринарен ветеринарен парапрофесионалист",
        getPrepared: "Основи",
        PCP: "2,3",
        PVS: "II-5 3",
        EuFMDCourses: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "Първа линия 1.2A Средно ниво 2.8aii",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да пишете оперативни ръководства/SOP въз основа на национални планове, да подкрепяте проектирането на обучение, да проектирате и наблюдавате симулационни упражнения, да идентифицирате пропуски в обучението или политиката чрез доклади след действие, да подкрепяте индустрията в разработването на техните планове за непрекъснатост на бизнеса и реакция, да разработвате образователни материали за индустрията.",
        role: "Ветеринарен съветник",
        getPrepared: "Основи",
        PCP: "3",
        PVS: "II-5 4",
        EuFMDCourses: "Sim Ex",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "Средно ниво 3.9i",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да пишете или актуализирате национални планове въз основа на доклади след действие, най-новите научни знания, законодателство, анализ на риска, да разработвате инструменти за одит/SOP за проверка на активирани планове.",
        role: "Разработчик на политики",
        getPrepared: "Основи",
        PCP: "3",
        PVS: "II-5 4-5",
        EuFMDCourses: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
    ],
  },
  "Emergency response": {
    definition:
      "Способността за бърза реакция на огнище на болест или извънредна ситуация в областта на безопасността на храните, включва способността за провеждане на първоначално разследване и заемане на конкретна роля (оценка, изхвърляне, почистване и дезинфекция, контрол на дивата природа по време на извънредна ситуация).",
    competency: "Реакция при извънредни ситуации",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да опишете дейностите (и тяхната цел) на реакция при инцидент, да намерите ръководни документи/SOPs.",
        role: "Няма",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "II-5 2",
        EuFMDCourses: "",
        ISAVET: "Подготовка 11.2",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да използвате специфични за болестта планове за подготовка за първоначално разследване, да опишете дефиницията на случай, да асистирате при първоначално или огнищно разследване (вижте епи, вземане на проби, контрол на движението, компетенции по биосигурност), да изпълнявате специфични технически умения (под надзор) в зависимост от ролята на индивида в огнището (прилагане на методи за изхвърляне, извършване на оценка, почистване и дезинфекция, контрол на дивата природа), да можете да използвате необходимото оборудване, да използвате ветеринарна експертиза за сътрудничество и подкрепа на правоприлагащи интервенции.",
        role: "Частен практикуващ, нает във VS, Ден 1 нает във VS, Ветеринарен парапрофесионалист",
        getPrepared: "Извънредна ситуация",
        PCP: "2,3",
        PVS: "II-5 3 II-6 3 IV-6 2,3 II-3 3",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете самостоятелно да изпълнявате специфични технически умения в зависимост от ролята на индивида в огнището (прилагане на методи за изхвърляне, извършване на оценка, почистване и дезинфекция, контрол на дивата природа), да търсите нови случаи - да поддържате оборудване - да подкрепяте и инструктирате реагиращите в задачи, свързани с ролята (водене на екип), да провеждате първоначално или огнищно разследване (вижте епи, вземане на проби, контрол на движението, компетенции по биосигурност), дейности, свързани с правоприлагане, запазване на местопроизшествието, верига на съхранение на проби/доказателства, каква информация да се предостави за успешно разследване/преследване.",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Извънредна ситуация",
        PCP: "2,3",
        PVS: "II-5 3 II-6 3 IV-6 2,3 II-3 3",
        EuFMDCourses: "ASF",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "Първа линия 2.8C",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да одобрявате препоръки за специфични ролеви протоколи, да проектирате и избирате стратегия за реакция, да предвиждате вероятността за постигане на целите на реакцията, да дефинирате резултатите.",
        role: "Разработчик на политики",
        getPrepared: "Извънредна ситуация",
        PCP: "3",
        PVS: "",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "",
        USAID: "",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да приложите концепцията за механизми за координация на инциденти, включително международна координация и комуникация по време на национални инциденти, да заемете специфична функционална позиция в рамките на структурата за извънредни ситуации на национално ниво, да ръководите действията на други на национално ниво, да разпознаете политическите чувствителности и да намерите решения, кризисна комуникация на национално ниво.",
        role: "Разработчик на политики",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "II-6 A 4-5",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
    ],
  },
  "Veterinary Products": {
    definition:
      "означава лекарства, инсектициди/акарициди, ваксини и биологични продукти, използвани или представени като подходящи за употреба за предотвратяване, лечение, контрол или изкореняване на животински вредители или болести; с профилактичен, терапевтичен или диагностичен ефект, променят физиологични функции или се дават на животни за установяване на ветеринарна диагноза; или за възстановяване, коригиране или модифициране на органични функции в животно или група животни.",
    competency: "Ветеринарни продукти",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да съветвате относно ветеринарни продукти за лечение, управление и/или предотвратяване на често срещани инфекциозни и неинфекциозни болести, основни репродуктивни състояния, травми и други извънредни ситуации при домашни животински видове, да опишете характеристиките, категориите и разумната употреба на ветеринарни продукти, да определите вида на животното, пътя на приложение, дозировката на лекарството, периода на приложение, да прилагате ветеринарни продукти/биологични продукти, да съобщавате за нежелани ефекти, включително развитие на антимикробна резистентност, да съхранявате и обработвате продукти (включително изисквания за студена верига), да обясните и определите времето за изтегляне на лекарството, да прилагате регулаторни и административни контроли върху AMR, AMU, да участвате в наблюдение на AMR/AMU, да демонстрирате осведоменост кога да потърсите професионална подкрепа и съвет.",
        role: "Ветеринарен парапрофесионалист",
        getPrepared: "Основи/ Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses: "VVPV",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да предписвате ветеринарни продукти, да опишете механизмите, водещи до развитие на антимикробна резистентност, да намерите и интерпретирате информация относно връзката между употребата на антимикробни средства в хранителни животни и развитието на антимикробна резистентност в патогени от значение за човека, да демонстрирате осведоменост къде да потърсите професионална подкрепа и съвет, да съобщавате тази информация на властите и заинтересованите страни.",
        role: "Частен практикуващ, нает във VS, Ден 1 нает във VS",
        getPrepared: "Основи/Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses: "FEPC VVPV",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да опишете регулаторни и административни контроли за внос, производство и регистрация на ветеринарни биологични продукти, да определите съответствието с регулациите (напр. одит на производители, инспекции на внос и т.н.), да прилагате наблюдение на AMR.",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Основи/Извънредна ситуация",
        PCP: "2,3",
        PVS: "II-8 3,4",
        EuFMDCourses: "VVPV PVM",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да одобрявате нови продукти, да подкрепяте проектирането на програми за наблюдение на AMR.",
        role: "Ветеринарен съветник",
        getPrepared: "Основи/ Извънредна ситуация",
        PCP: "2,3",
        PVS: "II-8 3,4",
        EuFMDCourses: "",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да проектирате програми за наблюдение на AMR (NAP), да пишете и актуализирате политики за регулиране на ветеринарни лекарства и биологични продукти (въз основа на най-новата наука, най-добрите практики на OIE, текущи условия), да разпознаете въздействието на извънредни ситуации върху прекъсванията на веригата за доставки и международните ограничения върху движението на биологични продукти.",
        role: "Разработчик на политики",
        getPrepared: "Основи/ Извънредна ситуация",
        PCP: "2,3",
        PVS: "II-8 4,5",
        EuFMDCourses: "VVPV PVM",
      },
    ],
  },
  "Animal Welfare": {
    definition:
      "Означава физическото и психическото състояние на животното (как се справя) във връзка с условията, в които живее и умира. Животното е в добро състояние на благосъстояние, ако (както е посочено от научни доказателства) е здраво, удобно, добре хранено, безопасно, способно да изразява вродено поведение и ако не страда от неприятни състояния като болка, страх и стрес. Доброто благосъстояние на животните изисква превенция на болести и ветеринарно лечение, подходящо убежище (когато е уместно), управление, хранене, хуманно отношение и хуманно клане/убиване. Благосъстоянието на животните се отнася до състоянието на животното.",
    competency: "Благосъстояние на животните",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да обясните значението на връзката между човека и животното, да опишете изразяването на болка и стрес при съответните животински видове (вземете предвид възраст, порода, физиологични или патологични промени), да използвате техники и оборудване за работа с животни, да обясните благосъстоянието на животните и свързаните с него отговорности на собственици, гледачи, ветеринари, да намерите информация относно местни, национални и международни регулации/стандарти за благосъстояние на животните, да опишете хуманни методи за: животновъдство; транспорт; клане за човешка консумация и убиване за целите на контрол на болестите. Опишете въздействието на управлението на извънредни ситуации и бедствия върху благосъстоянието на животните.",
        role: "Ден 1 нает във VS",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "I-13 3",
        EuFMDCourses: "",
        ISAVET: "Етика/Професионализъм 11.3",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да прилагате програма за съответствие с благосъстоянието на животните, да идентифицирате проблеми с благосъстоянието на животните (може да включва системи за оценка на програми за осигуряване) да докладвате и участвате в коригиращи действия), да извършвате хуманна евтаназия на отделни животни (каптивен болт, цервикална дислокация и т.н.) включва способността да се грижите за и поддържате необходимото оборудване, да оценявате хуманното клане зашеметяване/кървене, да определите метода на задържане (химически физически), като вземете предвид опасенията за благосъстоянието, да разпознаете въздействието на извънредни ситуации и бедствия върху благосъстоянието на животните.",
        role: "Частен практикуващ, нает във VS, Ветеринарен парапрофесионалист",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "II-13 3,4,5",
        EuFMDCourses: "",
        ISAVET: "Етика/Професионализъм 11.3",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да прилагате хуманни методи за унищожаване при масови депопулации, включва способността да се грижите за и поддържате необходимото оборудване, да водите екип, да обобщавате въздействието на мерките за контрол върху здравето и благосъстоянието на животните (като контрол на движението), да извършвате некропсия при разследване на благосъстоянието.",
        role: "Напълно функционален държавен полеви ветеринар",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "II-13 3,4,5",
        EuFMDCourses: "",
        ISAVET: "Етика/Професионализъм 11.3",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да предоставяте насоки на полеви персонал относно прилагането и интерпретацията на програми за благосъстояние в сложни ситуации, да избирате хуманни методи за унищожаване при масови депопулации (CO2, пяна, каптивен болт, свободен куршум, фармацевтични и т.н.) като превантивно клане, хуманно клане или унищожаване по време на огнища на болести, да адаптирате процедури въз основа на нуждите от реакция, да проектирате обучение, да оценявате нововъзникващи технологии и да правите препоръки.",
        role: "Ветеринарен съветник",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "II-13 3,4,5",
        EuFMDCourses: "",
        ISAVET: "",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да пишете и актуализирате програми за съответствие, използвайки научни статии, международни политики и насоки, включително работа със заинтересовани страни, да предвидите социалното приемане на действия за реакция, да предвидите въздействията на ветеринарните решения.",
        role: "Разработчик на политики",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "II-13 3,4,5",
        EuFMDCourses: "",
        ISAVET: "",
      },
    ],
  },
  "Risk Analysis": {
    definition:
      "Риск означава вероятността за настъпване и вероятната величина на биологичните и икономическите последици от неблагоприятно събитие или ефект върху здравето на животните или хората. (Риск = вероятност X последици) Процесът на анализ на риска включва идентификация на опасностите, оценка на риска, управление на риска и комуникация на риска.",
    competency: "Анализ на риска",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да обясните концепциите за анализ на риска: идентификация на опасностите, оценка на риска, бърза оценка на риска, управление на риска, комуникация на риска, да обясните как анализът на риска може да се използва за осигуряване на адекватна защита на ветеринарните услуги за здравето на животните и хората, околната среда в извънредни ситуации, не извънредни ситуации и природни бедствия.",
        role: "Ден 1 нает във VS Частен практикуващ, нает във VS",
        getPrepared: "Няма",
        PCP: "0",
        PVS: "",
        EuFMDCourses: "RAVC Анализ на риска и търговия Управление на криза",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да намерите информация, необходима за анализ на риска, да обясните как анализът на риска може да се приложи за оценка на риска от извънредно планиране/смекчаване/подготовка за реакция и възстановяване на болести по животните, остатъци от ветеринарни лекарства, включително внос на животни и животински продукти.",
        role: "Няма",
        getPrepared: "Няма",
        PCP: "0",
        PVS: "",
        EuFMDCourses: "",
        ISAVET: "",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да използвате основни/ключови концепции и принципи на анализа на риска в съответствие с предписаната политика и процедури/насоки (наличие на болест е TAD или FAST), да предавате рисковете от предаване на болести, да картографирате местни вериги на стойност.",
        role: "Напълно функционален държавен полеви ветеринар Ветеринарен парапрофесионалист",
        getPrepared: "Предупреждение",
        PCP: "1,2,3",
        PVS: "II-2 3",
        EuFMDCourses: "RAVC",
        ISAVET: "Комуникация 10.2 Подготовка 11.3",
        BTSF: "Подготовка за животински болести/ ранно предупреждение/ план за извънредни ситуации/контрол на болестите",
        USAID: "Средно ниво 1.5a-biii",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да прилагате принципите на анализа на риска в необичайни/сложни ситуации, да подкрепяте полеви OV в процеса на вземане на решения, особено когато са необходими отклонения от предписаната политика или ситуацията е сложна (разширяващо се огнище), да определите мерки за комуникация на риска (разбиране на ясни роли и отговорности), връзка между оценка на риска, управление на риска и комуникация на риска, преглед на съществуващи и нови заплахи, идентифициране на рисковете от предаване на болести.",
        role: "Ветеринарен съветник",
        getPrepared: "Предупреждение",
        PCP: "",
        PVS: "",
        EuFMDCourses: "Управление на криза Риск базирано наблюдение",
        ISAVET: "",
        BTSF: "",
        USAID: "Средно ниво 1.7 Средно ниво 3.9iv",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да извършвате анализ на риска на организационно ниво (идентифициране и анализ на пътищата на предаване на болести и свързаните с тях вериги на стойност за идентифициране на горещи точки за риск за FAST влизане и разпространение), да предоставяте резултати от оценка на риска за подкрепа на управлението на риска и решения за политика/програма (идентифициране на опции за контрол, оценка на очакваното въздействие и осъществимост на идентифицираните опции за контрол) и подкрепа на комуникацията на риска, подкрепа на разработването на стратегия за комуникация на риска.",
        role: "Разработчик на политики",
        getPrepared: "Основи",
        PCP: "",
        PVS: "II-2 4-5",
        EuFMDCourses:
          "RAVC Анализ на риска и търговия работилница Риск базирано наблюдение",
        ISAVET: "",
        BTSF: "",
        USAID: "Средно ниво 1.5biv-v",
      },
    ],
  },
  "Safety, Health and Well being": {
    definition:
      "Можете да опишете способността да защитавате физическото и психическото здраве на себе си и на тези, които работят около вас.",
    competency: "Безопасност, здраве и благополучие",
    differentLevels: [
      "Осведоменост",
      "Начинаещ",
      "Компетентен",
      "Професионалист",
      "Експерт",
    ],
    levels: [
      {
        name: "Осведоменост",
        behaviour:
          "Можете да обясните концепциите за ситуационна осведоменост - да опишете опасностите на работното място, включително изискващи работни часове/условия/задачи и травматични, екологични, химически и радиологични опасности и процеси за смекчаване. - да опишете инфекциозни и неинфекциозни здравни опасности и процеси за смекчаване, да опишете поведенческите/психологическите здравни рискове за ветеринарни професионалисти и процеси за смекчаване на поведенческите здравни проблеми и увеличаване на устойчивостта, да разпознаете принципите на здравето и безопасността на животните, хората и околната среда в извънредни ситуации и бедствия, за да позволите разпознаване и стратегическа реакция на въздействията на One Health, да опишете въздействието на управлението на извънредни ситуации и бедствия върху поминъка.",
        role: "Ден 1 Частен практикуващ, нает",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses: "Управление на криза при болести FAST",
      },
      {
        name: "Начинаещ",
        behaviour:
          "Можете да разпознаете признаци на влошаващо се благополучие в себе си, да идентифицирате източници на подкрепа (лична и организационна), да разпознаете небезопасни работни среди.",
        role: "Няма",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses: "Управление на криза при болести FAST",
      },
      {
        name: "Компетентен",
        behaviour:
          "Можете да разпознаете признаци на влошаващо се благополучие в другите (първа помощ за психично здраве), интервенция за предотвратяване на по-нататъшна вреда (насочване към подкрепа, контакт с властите), да участвате в процеса на докладване на събития, да идентифицирате основната причина и да направите препоръки, да разпознаете небезопасни работни среди и да предприемете действия за коригиране.",
        role: "Полеви ветеринарен ветеринарен парапрофесионалист",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses: "Управление на криза при болести FAST",
      },
      {
        name: "Професионалист",
        behaviour:
          "Можете да водите разследване на инциденти или близки пропуски, да направите препоръки за намаляване на вероятността от психически или физически здравни опасности.",
        role: "Съветник",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses: "",
      },
      {
        name: "Експерт",
        behaviour:
          "Можете да пишете/актуализирате политики и процедури за защита на безопасността, здравето и благополучието на служителите.",
        role: "Автор на политики",
        getPrepared: "Извънредна ситуация",
        PCP: "",
        PVS: "",
        EuFMDCourses: "",
      },
    ],
  },
};
