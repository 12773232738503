<template>
  <div
    id="sidebar"
    :class="[toggled ? `w-64` : `w-0`]"
    class="fixed h-full z-50 top-0 left-0 bg-green-primary text-green-100 overflow-auto"
  >
    <div>
      <div class="px-2 py-3 mt-20">
        <p class="top-1 right-3 absolute cursor-pointer">
          <font-awesome-icon icon="times" @click="closeSideBar" />
        </p>
      </div>
      <div
        class="flex flex-col justify-center p-5 mt-20"
        :class="[toggled ? `` : `hidden`]"
      >
        
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-tom focus:bg-green-secondary focus:outline-none focus:shadow-outline"
            to="/"
          >
            <font-awesome-icon icon="home" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
              >Coming Soon</span
            >
            {{ $t("sidebar.home") }}</router-link
          >
        <!-- <div>
					<router-link
						class="mt-10 flex gap-3 items-baseline px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-tom focus:bg-green-secondary focus:outline-none focus:shadow-outline"
						to="/CM"
					>
						<font-awesome-icon icon="user" class="text-xl" />
						<span class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center">CM</span>
						Country Manager</router-link
					>
				</div> -->

          <router-link
            class="flex gap-3 items-baseline px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-tom focus:bg-green-secondary focus:outline-none focus:shadow-outline"
            to="/AddUserData"
            v-if="userRole === 1"
          >
            <font-awesome-icon icon="file-csv" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
              >{{ $t("sidebar.add_user_data") }}</span
            >
            {{ $t("sidebar.add_user_data") }}
          </router-link>


          <router-link
            class="flex gap-3 items-baseline px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-tom focus:bg-green-secondary focus:outline-none focus:shadow-outline"
            to="/adjust-levels"
            v-if="userRole === 1"
          >
            <font-awesome-icon icon="file-csv" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
            >
              {{ $t("sidebar.adjust_competency_levels") }}</span
            >
            {{ $t("sidebar.adjust_competency_levels") }}
          </router-link>


          <router-link
            class="flex gap-3 items-baseline px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-tom focus:bg-green-secondary focus:outline-none focus:shadow-outline"
            to="/CustomReport"
            v-if="userRole === 1"
          >
            <font-awesome-icon icon="file-csv" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
              >{{ $t("sidebar.custom_reports") }}</span
            >
            {{ $t("sidebar.custom_reports") }}</router-link
          >


       
          <router-link
            class="flex gap-3 items-baseline px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-tom focus:bg-green-secondary focus:outline-none focus:shadow-outline"
            to="/Courses"
            v-if="userRole === 1"
          >
            <font-awesome-icon icon="clipboard-list" class="text-xl" /><span>{{
              $t("sidebar.courses")
            }}</span>
          </router-link>


          <router-link
            class="flex flex-start gap-3 items-center px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-tom focus:bg-green-secondary focus:outline-none focus:shadow-outline"
            to="/CompetencyFramework"
            v-if="userRole === 1"
          >
            <font-awesome-icon icon="book" class="text-xl" />
            {{ $t("sidebar.competency_framework") }}
          </router-link>

          <router-link
          class="has-tooltip flex gap-2 items-center px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-secondary focus:bg-green-tom focus:outline-none focus:shadow-outline"
          to="/TrainingCreditSystem"
          v-if="userRole === 1 || userRole === 4"
        >
          <font-awesome-icon icon="money-check" class="" />

          <span>{{ $t("sidebar.training_credit_system") }}</span>
          </router-link>

        <router-link
          class="flex gap-3 items-baseline px-1 py-2 bg-transparent rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-green-tom focus:bg-green-secondary focus:outline-none focus:shadow-outline"
          to="/Suggestions"
        >
          <font-awesome-icon icon="pen-alt" class="text-xl" />
          {{ $t("sidebar.leave_a_suggestion") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: ["toggled", "userRole"],
  data() {
    return {
      
    }
  },
  methods: {
    closeSideBar() {
      // notify parent component (App) of sidebar closing
      this.$emit("sideBarToggled", false);
    },
    openSideBar() {
      this.$emit("sideBarToggled", true);
    },
  },

  created() {
    //add event listener so sidebar closes when user clicks outside of it

    window.addEventListener("click", (e) => {
      //let target = e.target
      //et name = target.getAttribute("name");
      // if (name === "open") {
      //   this.$emit("sideBarToggled", true);
      // } else
      if (!this.$el.contains(e.target)) {
        this.$emit("sideBarToggled", false);
      }
    });
  },
};
</script>

<style scoped>
#sidebar {
  transition: width 1s;
}
</style>
