import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import Vidle from "v-idle-3";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLongArrowAltUp,
  faLongArrowAltDown,
  faHome,
  faUser,
  faMoneyCheck,
  faClipboardList,
  faUserGraduate,
  faQuestionCircle,
  faTimes,
  faBars,
  faSignOutAlt,
  faInfoCircle,
  faAngleDown,
  faAngleUp,
  faCircle,
  faCalendarAlt,
  faPenAlt,
  faCheckCircle,
  faBook,
  faDownload,
  faHandPointUp,
  faTimesCircle,
  faFileCsv,
  faEye,
  faEyeSlash,
  faEllipsisH,
  faTrash,
  faUserCircle,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import DKToast from "vue-dk-toast";

import i18n from "./i18n";

library.add(faHome);
library.add(faUser);
library.add(faMoneyCheck);
library.add(faClipboardList);
library.add(faUserGraduate);
library.add(faQuestionCircle);
library.add(faTimes);
library.add(faBars);
library.add(faCircle);
library.add(faSignOutAlt);
library.add(faLongArrowAltUp);
library.add(faLongArrowAltDown, faLongArrowAltUp);
library.add(faInfoCircle);
library.add(faAngleDown);
library.add(faAngleUp);
library.add(faPenAlt);
library.add(faCalendarAlt);
library.add(faCheckCircle);
library.add(faUserCircle);
library.add(faBook);
library.add(faDownload);
library.add(faHandPointUp);
library.add(faTimesCircle);
library.add(faFileCsv);
library.add(faEye);
library.add(faEyeSlash);
library.add(faEllipsisH);
library.add(faTrash);
library.add(faCaretDown);

const dkOptions = {
  positionX: "center",
  positionY: "top",
  duration: 3500,
  class: ["shadow-md", "border", "border-2"],
  max: 3,
  styles: {
    backgroundColor: "#fff",
    color: "#000",
    padding: "15px",
    lineHeight: 1.7,
  },
};

createApp(App)
  .use(router)
  .use(store)
  .use(DKToast, dkOptions)
  .use(Vidle)
  .use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
