<template>
  <div
    class="flex flex-col min-h-screen bg-gray-50 relative scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 scrollbar-thin"
  >
    <Navbar
      :toggled="sideBarToggled"
      @sideBarToggled="setSideBarToggled"
      :auth="auth"
      :adminAuth="adminAuth"
      @auth="setAuth"
      @adminAuth="setAdminAuth"
      @closeModal="showRegionModal = false"
      :key="componentKey"
    />

    <!--content-->
    <div class="flex-1 relative overflow-auto">
      <v-idle
        v-if="auth && (role === 1 || role === 2)"
        class="hidden"
        @idle="logoutIdleUser"
        :duration="900"
        :reminders="[0]"
        @remind="this.showLogoutModal = true"
      />
      <!-- <on-click-outside :do="close"> -->
      <Sidebar
        @sideBarToggled="setSideBarToggled"
        :toggled="sideBarToggled"
        v-if="auth"
        :userRole="role"
      />
      <!-- </on-click-outside>  -->

      <div class="relative">
        <LogoutModal
          v-if="showLogoutModal"
          @closeModal="showLogoutModal = false"
        />
        <router-view
          @auth="setAuth"
          @adminAuth="setAdminAuth"
          :country="country"
          :role="role"
          :id="id"
          class="wrapper"
        />
      </div>
      <spinner v-if="loading" />
    </div>
    <!-- footer -->
    <div class="h-100 bg-green-primary text-white font-light font-marta">
      <div class="p-8 lg:p-10">
        <img
          class="w-36 md:w-56 h-auto"
          src="/static/assets/EU+LETTERS_negative.png"
          alt="European Union logo"
        />

        <div class="flex justify-between items-center mt-6 lg:mt-8">
          <div class="space-y-3">
            <p>
              European Commission for the Control of Foot-and-Mouth Disease
              <br />
              MOVE FAST strategy. Foot-and-mouth And Similar Transboundary
              animal diseases
            </p>
            <p>www.fao.eufmd.org</p>
            <p>© {{ currentYear }} | EuFMD</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Sidebar from "@/components/Sidebar";
import api from "@/services/data";
import LogoutModal from "./components/LogoutModal.vue";
import Spinner from "@/components/Spinner";

export default {
  name: "App",
  components: {
    Navbar,
    Sidebar,
    LogoutModal,
    Spinner,
  },
  data() {
    return {
      auth: false,
      adminAuth: false,
      country: "",
      role: "",
      sideBarToggled: false,
      learners: "",
      positions: "",
      id: "",
      currentYear: new Date().getFullYear(),
      showLogoutModal: false,
      showRegionModal: false,
      regions: [],
      capitalGeoLoc: {},
      loading: false,
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    setAuth(status) {
      this.auth = status;
    },
    setAdminAuth(status) {
      this.adminAuth = status;
    },
    setSideBarToggled(status) {
      this.sideBarToggled = status;
    },
    async getCountryRegions(country) {
      this.loading = true;

      this.$toast(`Loading country regions.`, {
        styles: {
          backgroundColor: "#CADFBF",
        },
      });

      const regions = await (
        await fetch(`https://countriesnow.space/api/v0.1/countries/states`, {
          method: "POST",
          body: JSON.stringify({ country: country }),
          headers: {
            "Content-Type": "application/json",
          },
        })
      ).json();

      if (!regions.data) {
        this.$toast(`Country regions not found.`, {
          styles: {
            backgroundColor: "#CADFBF",
          },
        });
      }

      this.regions = regions.data.states.map(region => region.name);

      localStorage.setItem(country, JSON.stringify(this.regions));
      this.loading = false;
    },

    async getCachedRegions(country) {
      let regions = [];
      for (let i = 0; i < localStorage.length; i++) {
        localStorage.key(i) === country
          ? (regions = localStorage.getItem(country))
          : (regions = this.getCountryRegions(country));
      }
      return regions;
    },

    async isLoggedIn() {
      //check if there's token in local storage
      let token = localStorage.getItem("token");
      //let resetToken = localStorage.getItem("resetToken");

      //if logged in change auth to true & get user profile, if not redirect to login page
      //resetToken is checking if there exists a restToken for password reset. there was an issue here before where the password reset link could not be reached
      if (token) {
        let parsed = JSON.parse(atob(token.split(".")[1]));
        let role = parsed.user_role;
        //let countryId = parsed.country_id;

        this.role = role;
        //this.getData(role);
      } else {
        this.$router.replace({ name: "Login" });
      }
    },

    async getData(role) {
      //this.loading = true;
      if (role === 1 || role === 2 || role === 4) {
        const res = await api.getProfile();
        const countryRegions = await api.getRegions();

        this.username = res.name;
        this.country = res.country;
        this.role = res.role_id;
        this.id = res.id;

        this.auth = true;
        if (!res.region || res.region === "null" || res.region === "") {
          role === 1 || role === 2 ? (this.showRegionModal = true) : null;

          //set regions
          this.regions = countryRegions.map(region => region.region);

          //geolocation of capital city for map center
          const capital = countryRegions.filter(region => region.capital === 1);
          localStorage.setItem(
            `${capital[0].region} ${this.country} Capital`,
            JSON.stringify(capital[0].coordinates)
          );
        } else {
          //geolocation of capital city for map center
          const capital = countryRegions.filter(region => region.capital === 1);
          localStorage.setItem(
            `${capital[0].region} ${this.country} Capital`,
            JSON.stringify(capital[0].coordinates)
          );
        }

        this.$store.state.id = this.id;
        this.$store.state.role = this.role;
        //this.$store.state.country = country_id;
        this.$store.state.countryName = this.country;
      } else if (role === 3) {
        const res = await api.getAdminProfile();

        this.username = res.username;
        this.country = " ";
        this.role = res.role_id;
        this.id = res.id;

        this.adminAuth = true;

        this.$store.state.id = this.id;
        this.$store.state.role = this.role;
      }
    },
    logoutIdleUser() {
      // condition prevents fn from continuously trying to send to the logout route after automatic logout
      if (localStorage.getItem("token")) {
        this.showRegionModal === true ? (this.showRegionModal = false) : null;
        api.sendLogoutTime();
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("auth");
        //localStorage.clear();
        this.$router.replace({ name: "Login" });

        this.auth = false;
        this.showLogoutModal = true;
      }
    },
  },
  created() {
    if (localStorage.getItem("auth") === "true") {
      // Set the auth variable to true if it is set in local storage
      this.auth = true;
    }
    if (localStorage.getItem("adminAuth") === "true") {
      // Set the auth variable to true if it is set in local storage
      this.adminAuth = true;
    }
    if (
      this.$route.meta.requiresResetAuth ||
      this.$route.meta.requiresAuth ||
      this.$route.meta.requiresAdminAuth
    ) {
      this.isLoggedIn().then(this.getData(this.role));
    }
  },
  watch: {
    $route() {
      this.sideBarToggled = false;
    },
    auth: async function () {
      if (this.auth === true) {
        this.isLoggedIn().then(await this.getData(this.role));
      }
    },
  },
};
</script>

<style>
.wrapper {
  min-height: 100%;
}
</style>
